import React from "react";

const FeatureCard = ({ imageSrc, title, description, altText,id }) => (
  <div className="feature-card" key={id}>
    <img src={imageSrc} alt={altText} className="feature-image" />
    <h2 className="feature-title">{title}</h2>
    {description.map((text, index) => (
      <div style={{ width: "80%" }}>
        <p key={index} className="feature-description">
          {text}
        </p>
      </div>
    ))}
  </div>
);

function SportsHighlights() {
  const features = [
    {
      id:1,
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/5d3932e3058e6e7abb7dd47c6370d8d49d1a47a35c52a4e0bb157a4e1ec11b8f?apiKey=74b2fddf0d83436fad78ac66773acda0&",
      title: "KNOW EVERYTHING",
      description: [
        "Know what to watch, where to watch and when to watch. Who you watch it with is up to you!",
      ],
      altText: "Informative icon",
    },
    {
      id:2,
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/f968adbc9964650e5959a551d5644b44f87c381a3f28751d0cb3edff7d32ce72?apiKey=74b2fddf0d83436fad78ac66773acda0&",
      title: "GET NOTIFIED",
      description: [
        "Want to be reminded of when your favorite shows and movies are out? We got you covered!",
      ],
      altText: "Notifications icon",
    },
    {
      id:3,
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/596837fdf3d627d06ae9e60de8a913ca240c053795e79ee2c1f779dd931c127e?apiKey=74b2fddf0d83436fad78ac66773acda0&",
      title: "LIMITED AVAILABILITY",
      description: [
        "Truflix is invite-only and available to a limited amount of people in your region. Get in now!",
      ],
      altText: "Exclusive icon",
    },
  ];
  return (
    <>
      <div className="container" data-aos="fade-up" data-aos-duration="3000">
        <div className="row justify-content-center">
          {features.map((feature, index) => (
            <div className="col-lg-4 col-md-4 col-sm-12  " key={feature.id}>
              <FeatureCard key={feature.id} {...feature} />
            </div>
          ))}
        </div>
      </div>
      {/* </div>
    </>
    <main className="main-content">
      <section className="features-section">
        {features.map((feature, index) => (
          <FeatureCard key={index} {...feature} />
        ))}
      </section> */}
      <style jsx>{`
        .main-content {
          background-color: #000;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 50px;
        }
        @media (max-width: 991px) {
          .feature-card {
            margin-bottom: 25px;
          }
          .feature-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: calc(33% - 20px);
            color: #fff;
          }
        }
        @media (max-width:900px){
          .feature-card{
            width:100%;
          }
        }
        .features-section {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          align-items: center; 
          justify-content: center;
          max-width: 90%;
          margin-top: 40px;
        }
        .feature-card {
          display: flex;
          flex-direction: column;
          align-items: center;
          // width: calc(33% - 20px);
          color: #fff;
          text-align: center; /* Center text inside card */
        }
        .feature-image {
          width:105.9px;
          height:105.9px;
          display: block;
          margin: 0 auto; /* Center the image */
        }
        .feature-title {
          margin-top: 44px;
          font-size: 2rem;
      font-weight: 700;
          text-transform: uppercase;
        }
        .feature-description {
          // margin-top: 32px;
          
          font-size: 18px;
          font-weight: 400;
          padding: 16px;
          line-height:22px;
        }
        // @media (max-width: 991px) {
        //   .features-section {
        //     flex-direction: column;
        //     gap: 0;
        //   }
          .feature-card {
            width: 100%;
            align-items: stretch;
            margin-top: 40px;
          }
          .feature-title,
          .feature-description {
            text-align: center;
          }
        }
        @media (max-width: 767px) {
          .feature-title {
            font-size: 35px;
            line-height: 37px;
            
            margin-top: 40px;
          }
          .feature-description {
            font-size: 18px;
            margin-top: 20px;
          }
          // .feature-image {
          //   width: 50%;
          //   height: 50%;
          //   align-self: center;
          // }
        }
      `}</style>
    </>
  );
}

function ImageColumn({ src, alt }) {
  return (
    <div className="image-column">
      <img loading="lazy" src={src} alt={alt} className="highlight-image" />
      <style jsx>{`
        .image-column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 0;
        }
        // @media (max-width: 991px) {
        //   .image-column {
        //     width: 100%;
        //   }
        // }
        .highlight-image {
          width: 100%;
          aspect-ratio: 1.54;
          object-fit: cover;
          object-position: center;
          flex-grow: 1;
        }
        // @media (max-width: 991px) {
        //   .highlight-image {
        //     max-width: 100%;
        //     margin-top: 40px;
        //   }
        // }
      `}</style>
    </div>
  );
}

export default SportsHighlights;
