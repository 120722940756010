// import React, { useEffect, useRef, useState } from "react";
// import Header from "../../components/HeaderPricing/Header";
// import ScrollContainer from "react-indiana-drag-scroll";
// import airbender from "../../Assets/Images/images/airbender.png";
// import oppenheimer from "../../Assets/Images/images/oppenheimer.png";
// import revenge from "../../Assets/Images/images/revenge.png";
// import barbie from "../../Assets/Images/images/barbie.png";
// import angry from "../../Assets/Images/images/angry.png";
// import afraid from "../../Assets/Images/images/afraid.png";
// import { useNavigate } from "react-router-dom";
// const ShowsList = () => {
//   const navigate = useNavigate();
//   const [searchTerm, setSearchTerm] = useState("");
//   const [yearFilter, setYearFilter] = useState("");
//   const [genreFilter, setGenreFilter] = useState("");
//   const [lastSearch, setLastSearch] = useState("");
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage, setItemsPerPage] = useState(8); // Adjust items per page as needed

//   const movies = [
//     {
//       id: 1,
//       title: "THE LAST AIRBENDER",
//       genre: "Action",
//       year: 2023,
//       description: "",
//       image: "/images/2-m.png",
//     },
//     {
//       id: 2,
//       title: "OPPERNHEIMER",
//       genre: "Drama",
//       year: 2020,
//       description: "",
//       image: "/images/4-m.png",
//     },
//     {
//       id: 3,
//       title: "BARBIE: THE MOVIE",
//       genre: "Comedy",
//       year: 2021,
//       description: "Description for Movie 3",
//       image: "/images/7-m.png",
//     },
//     {
//       id: 4,
//       title: "DR. REVENGE AND THE ATTACK OF THE NERDS",
//       genre: "Comedy",
//       year: 2013,
//       description: "Description for Movie 3",
//       image: "/images/5-m.png",
//     },

//     {
//       id: 5,
//       title: "Movie 3",
//       genre: "Comedy",
//       year: 2013,
//       description: "Description for Movie 3",
//       image: "/images/5-m.png",
//     },
//     {
//       id: 6,
//       title: "Movie 3",
//       genre: "Comedy",
//       year: 2013,
//       description: "Description for Movie 3",
//       image: "/images/4-m.png",
//     },

//     {
//       id: 7,
//       title: "Movie 3",
//       genre: "Comedy",
//       year: 2013,
//       description: "Description for Movie 3",
//       image: "/images/7-m.png",
//     },

//     {
//       id: 8,
//       title: "Movie 3",
//       genre: "Comedy",
//       year: 2013,
//       description: "Description for Movie 3",
//       image: "/images/8-m.png",
//     },
//     {
//       id: 9,
//       title: "THE LAST AIRBENDER",
//       genre: "Action",
//       year: 2023,
//       description: "",
//       image: "/images/2-m.png",
//     },
//     {
//       id: 10,
//       title: "OPPERNHEIMER",
//       genre: "Drama",
//       year: 2020,
//       description: "",
//       image: "/images/4-m.png",
//     },
//     {
//       id: 11,
//       title: "BARBIE: THE MOVIE",
//       genre: "Comedy",
//       year: 2021,
//       description: "Description for Movie 3",
//       image: "/images/7-m.png",
//     },
//     {
//       id: 12,
//       title: "DR. REVENGE AND THE ATTACK OF THE NERDS",
//       genre: "Comedy",
//       year: 2013,
//       description: "Description for Movie 3",
//       image: "/images/5-m.png",
//     },

//     {
//       id: 13,
//       title: "THE LAST AIRBENDER",
//       genre: "Comedy",
//       year: 2020,
//       description: "Description for Movie 3",
//       image: "/images/5-m.png",
//     },
//     {
//       id: 14,
//       title: "Dune",
//       genre: "Comedy",
//       year: 2013,
//       description: "Description for Movie 3",
//       image: "/images/4-m.png",
//     },

//     {
//       id: 15,
//       title: "12th Fail",
//       genre: "Comedy",
//       year: 2013,
//       description: "Description for Movie 3",
//       image: "/images/12thfail.jpg",
//     },

//     {
//       id: 16,
//       title: "Movie 3",
//       genre: "Comedy",
//       year: 2013,
//       description: "Description for Movie 3",
//       image: "/images/8-m.png",
//     },

//     // Add more movies here
//   ];
//   const posters = [
//     {
//       id: 1,
//       src: airbender,
//       alt: "Movie poster 1",
//     },
//     {
//       id: 2,
//       src: oppenheimer,
//       alt: "Movie poster 2",
//     },
//     {
//       id: 3,
//       src: revenge,
//       alt: "Movie poster 3",
//     },
//     {
//       id: 4,
//       src: barbie,
//       alt: "Movie poster 4",
//     },
//     {
//       id: 5,
//       src: angry,
//       alt: "Movie poster 5",
//     },
//     {
//       id: 6,
//       src: afraid,
//       alt: "Movie poster 6",
//     },
//     {
//       id: 7,
//       src: afraid,
//       alt: "Movie poster 6",
//     },
//     {
//       id: 8,
//       src: afraid,
//       alt: "Movie poster 6",
//     },
//     {
//       id: 9,
//       src: afraid,
//       alt: "Movie poster 6",
//     },
//     {
//       id: 10,
//       src: afraid,
//       alt: "Movie poster 6",
//     },
//   ];

//   const [shows, setShows] = useState();

//   useEffect(() => {
//     const getShows = async () => {
//       await fetch("https://truflix.caferoute66.com/api/v1/entertainment/shows")
//         .then((response) => {
//           if (!response.ok) {
//             throw new Error(`HTTP error! status: ${response.status}`);
//           }
//           return response.json();
//         })
//         .then((data) => {
//           // Do something with the data, such as log it to the console
//           console.log("shows data", data);
//           setShows(data);
//         })
//         .catch((error) => {
//           console.error("Error:", error);
//         });
//     };
//     getShows();
//   }, []);

//   // Function to calculate total pages
//   const filteredMovies = movies.filter((movie) => {
//     return (
//       movie.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
//       (!yearFilter || movie.year.toString() === yearFilter) &&
//       (!genreFilter || movie.genre === genreFilter)
//     );
//   });

//   const totalPages = Math.ceil(filteredMovies.length / itemsPerPage);

//   const currentMovies = filteredMovies.slice(
//     (currentPage - 1) * itemsPerPage,
//     currentPage * itemsPerPage
//   );

//   const handleSearch = () => {
//     setCurrentPage(1); // Reset to first page when performing a new search
//     setLastSearch(
//       `Search Term: ${searchTerm}, Year: ${yearFilter}, Genre: ${genreFilter}`
//     );
//   };
//   const ShowPoster = ({ imageSrc, alt, imageNumber }) => (
//     <>
//       <h3
//         style={{
//           color: "#333333",
//           marginBottom: "10px",
//           fontWeight: "700",
//           fontFamily: "Montserrat",
//         }}
//       >
//         {imageNumber}
//       </h3>
//       <img className="movie-poster" src={imageSrc} alt={alt} loading="lazy" />
//     </>
//   );

//   const containerRef = useRef(null);

//   return (
//     <>
//       <Header />
//       <div className="container mt-5">
//         <h2 className="text-center text-white ">ENDLESS SHOWS</h2>
//         <h6 className="text-center text-white mb-2 pb-5">BY TRUFLIX</h6>
//         <div className="row justify-content-center mt-2">
//           <div className="col-md-3 mb-3">
//             <input
//               type="text"
//               className="form-control movies-field"
//               placeholder="Search by Title"
//               value={searchTerm}
//               onChange={(e) => setSearchTerm(e.target.value)}
//             />
//           </div>
//           <div className="col-md-3 mb-3">
//             <input
//               type="number"
//               className="form-control movies-field"
//               placeholder="Search by Year"
//               value={yearFilter}
//               onChange={(e) => setYearFilter(e.target.value)}
//             />
//           </div>
//           <div className="col-md-3 mb-3">
//             <select
//               className="form-control movies-field"
//               value={genreFilter}
//               onChange={(e) => setGenreFilter(e.target.value)}
//             >
//               <option value="">All Genres</option>
//               <option value="Action">Action</option>
//               <option value="Drama">Drama</option>
//               <option value="Comedy">Comedy</option>
//             </select>
//           </div>
//           <div className="col-md-3 mb-3">
//             <button
//               className="btn btn-primary search-btn-movies"
//               onClick={handleSearch}
//             >
//               <svg
//                 width="15"
//                 height="15"
//                 viewBox="0 0 22 22"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M15.0291 2.90032C11.4491 -0.429677 5.83861 -0.229726 2.50861 3.35027C-0.821392 6.93027 -0.621197 12.5403 2.9588 15.8703C6.1388 18.8303 10.9191 19.0003 14.2791 16.4903L19.2488 21.1103L21.4393 18.7603L16.4686 14.1403C18.7286 10.6003 18.2091 5.85031 15.0291 2.89031V2.90032ZM13.7586 13.8203C11.3086 16.4503 7.18841 16.6003 4.55841 14.1503C1.92841 11.7003 1.77931 7.58031 4.22931 4.95031C6.67931 2.32031 10.7985 2.17029 13.4285 4.62029C16.0585 7.07029 16.2086 11.1903 13.7586 13.8203Z"
//                   fill="#EDEDED"
//                 />
//               </svg>
//             </button>
//           </div>
//         </div>
//         <div className="row justify-content-center">
//           <div className="col-md-12 mb-3">
//             <h2 className="text-center text-white">SEARCH RESULTS</h2>
//             <p className="text-center text-white">Last Search: {lastSearch}</p>
//           </div>
//         </div>
//         <div className="row justify-content-center full-w">

//           <h3 className="scroll__h3">POPULAR NOW</h3>
//           <ScrollContainer className="movies-container" ref={containerRef}>
//             {shows && Array.isArray(shows) && shows.length > 0 ? (
//               shows.map((poster) => (
//                 <ShowPoster
//                   key={poster.id}
//                   imageNumber={poster.id}
//                   imageSrc={poster.src}
//                   alt={poster.alt}
//                 />
//               ))
//             ) : (
//               <p style={{ color: "white" }}>No shows available</p>
//             )}
//           </ScrollContainer>
//           <h3 className="scroll__h3">RECOMMENDED</h3>
//           <ScrollContainer className="movies-container">
//             {posters.map((poster) => (
//               <div style={{ width: "100%" }}>
//                 <a
//                   onClick={() =>
//                     navigate("/shows-details", {
//                       state: {
//                         show_id: poster.id,
//                         show_data: poster,
//                       },
//                     })
//                   }
//                   style={{ textDecoration: "none" }}
//                 >
//                   <ShowPoster
//                     key={poster.id}
//                     imageNumber={poster.id}
//                     imageSrc={poster.src}
//                     alt={poster.alt}
//                   />
//                 </a>
//               </div>
//             ))}
//           </ScrollContainer>
//           <h3 className="scroll__h3">BEST OF ALL TIME</h3>
//           <ScrollContainer className="movies-container">
//             {posters.map((poster) => (
//               <div style={{ width: "100%" }}>
//                 <a
//                   onClick={() =>
//                     navigate("/shows-details", {
//                       state: {
//                         show_id: poster.id,
//                         show_data: poster,
//                       },
//                     })
//                   }
//                   style={{ textDecoration: "none" }}
//                 >
//                   <ShowPoster
//                     key={poster.id}
//                     imageNumber={poster.id}
//                     imageSrc={poster.src}
//                     alt={poster.alt}
//                   />
//                 </a>
//               </div>
//             ))}
//           </ScrollContainer>
//           <h3 className="scroll__h3">COMEDY</h3>
//           <ScrollContainer className="movies-container">
//             {posters.map((poster) => (
//               <div style={{ width: "100%" }}>
//                 <a
//                   onClick={() =>
//                     navigate("/shows-details", {
//                       state: {
//                         show_id: poster.id,
//                         show_data: poster,
//                       },
//                     })
//                   }
//                   style={{ textDecoration: "none" }}
//                 >
//                   <ShowPoster
//                     key={poster.id}
//                     imageNumber={poster.id}
//                     imageSrc={poster.src}
//                     alt={poster.alt}
//                   />
//                 </a>
//               </div>
//             ))}
//           </ScrollContainer>
//         </div>
//         {/* Pagination */}
//         <div className="row">
//           <div className="col-md-12 text-center">
//             <nav>
//               <ul className="pagination">
//                 <li
//                   className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
//                 >
//                   <button
//                     className="page-link"
//                     onClick={() => setCurrentPage(currentPage - 1)}
//                   >
//                     Previous
//                   </button>
//                 </li>
//                 {Array.from({ length: totalPages }, (_, i) => (
//                   <li
//                     key={i}
//                     className={`page-item ${
//                       currentPage === i + 1 ? "active" : ""
//                     }`}
//                   >
//                     <button
//                       className="page-link"
//                       onClick={() => setCurrentPage(i + 1)}
//                     >
//                       {i + 1}
//                     </button>
//                   </li>
//                 ))}
//                 <li
//                   className={`page-item ${
//                     currentPage === totalPages ? "disabled" : ""
//                   }`}
//                 >
//                   <button
//                     className="page-link"
//                     onClick={() => setCurrentPage(currentPage + 1)}
//                   >
//                     Next
//                   </button>
//                 </li>
//               </ul>
//             </nav>
//           </div>
//         </div>
//       </div>
//       <style>{`
//         .movies-container {
//             width:100%;
//             height:20%;
//             display: flex;
//             gap: 1em;
//             padding-top:2em;
//             padding-bottom:3rem;
//             background: #000;
//             overflow-x: auto; /* Trigger scroll on overflow */
//             scrollbar-width: none; /* Firefox scrollbar */
//             -ms-overflow-style: none; /* IE scrollbar */
//           }
//           .movie-poster {
//             max-width: 100%; /* Ensure image doesn't overflow */
//             height: 70%; /* Maintain aspect ratio */
//             display: block;
//             margin: 30 auto;
//           }

//           h3 {
//             font-size: 3rem;
//             text-align: center;
//             margin-bottom: 5px;
//           }
//           .scroll__h3{
//             color:white;
//             text-align:left;
//             font-size:1.5rem;
//           }

//           .movies-container::-webkit-scrollbar {
//             display: none; /* Hide scrollbar for Chrome, Safari, Edge */
//           }
//           @media screen and (max-width: 768px) {
//             /* Adjustments for smaller screens */
//             .movies-container {
//               padding: 1em;
//             }
//             .header h1 {
//               font-size: 1.5em;
//             }
//             .movie-poster {
//                 max-width: 30%; /* Example size for smaller screens */
//                 margin: 0 auto;
//               }

//               .movie-poster h3 {
//                 font-size: 1rem;
//                 margin-bottom: 3px;
//               }
//               .scroll__h3 {
//                 color: white;
//                 text-align: left;
//                 font-size: 16px;
//             }
//           }
//       `}</style>
//     </>
//   );
// };

// export default ShowsList;

// import React, { useEffect, useState } from "react";
// import Header from "../../components/HeaderPricing/Header";
// import ScrollContainer from "react-indiana-drag-scroll";
// import { useNavigate } from "react-router-dom";
// import { BASEURL } from "../../API/constants";
// import Loader from "react-js-loader";
// import Footer from "../../components/Footer";

// const ShowList = ({ showHeader }) => {
//   const [movies, setMovies] = useState([]);
//   const [movies1, setMovies1] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [yearFilter, setYearFilter] = useState(""); // State for year dropdown
//   const [genreFilter, setGenreFilter] = useState("");
//   const [search, setSearch] = useState(false);
//   const [genres,setGenres] = useState()

//   const fetchGenres = async()=>{
//     try{
//       const response = await fetch("https://caferoute66.com/truflix/api/v1/entertainment/master/genres")
//       const data = await response.json()
//       // console.log("GENRES",data?.data)
//       setGenres(data?.data)
//     }catch(err){
//       console.log("GENRES ERROR: ",err)
//     }
//   }

//   useEffect(() => {
//     fetchData();
//     fetchGenres()
//   }, [currentPage, searchTerm, yearFilter, genreFilter]);

//   // Fetch data from the API
//   const fetchData = async () => {
//     try {
//       setLoading(true);
//       const response = await fetch(
//         `${BASEURL}/entertainment/get-categorized/shows`
//       );
//       const data = await response.json();

//       setMovies(data.data);
//       console.log("SHOWS",data?.data)
//       setTotalPages(data.last_page);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   const fetchSearchData = async () => {
//     try {
//       setLoading(true);
//       const response1 = await fetch(
//         `${BASEURL}/entertainment/shows?search=${searchTerm}&year=${yearFilter}&genres=${genreFilter}`
//       );
//       const data1 = await response1.json();
//       setMovies1(data1.data);
//       setLoading(false);
//       setSearch(true);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   const handleSearch = () => {
//     setCurrentPage(1);
//     fetchSearchData();
//   };

//   // Generate years from 2000 to current year
//   const currentYear = new Date().getFullYear();
//   const years = Array.from(
//     { length: currentYear - 1999 },
//     (_, index) => 2000 + index
//   );

//   const MoviePoster = ({ imageSrc, alt, imageNumber, movie }) => {
//     const navigate = useNavigate();
//     let data = localStorage.getItem("UserInfo");
//     return (
//       <>
//         <p className="poster-title">{imageNumber}</p>
//         <img
//           onClick={() => {
//             if (data == null) {
//               navigate("/get-subscription");
//             } else {
//               navigate("/shows-details", {
//                 state: {
//                   movie_id: movie.id,
//                   movie_data: movie,
//                 },
//               });
//             }
//           }}
//           className="movie-poster"
//           src={imageSrc}
//           alt={alt}
//           loading="lazy"
//         />
//       </>
//     );
//   };
//   const getYearOptions = () => {
//     const currentYear = new Date().getFullYear();
//     const years = [];
//     for (let year = 1900; year <= currentYear; year++) {
//       years.push(year)

//     }
//     return years;
//   };

//   return (
//     <>
//       <Header />
//       <div className="container-fluid mt-5">
//         <div className="row justify-content-center full-w">
//           <h2 className="text-center text-white">ENDLESS SHOWS</h2>
//           <h6 className="text-center text-white mb-2 pb-5">BY TRUFLIX</h6>

//           {/* Search inputs */}
//           <div className="container d-flex align-items-center justify-content-center flex-wrap">
//             <div className="col-md-4 col-sm-12 mb-3 mx-1">
//               <input
//                 type="text"
//                 className="form-control movies-field"
//                 placeholder="Search by Title"
//                 value={searchTerm}
//                 onChange={(e) => setSearchTerm(e.target.value)}
//               />
//             </div>
//             <div className="col-md-3 col-sm-12 mb-3 mx-2">
//               <select
//                 className="form-control movies-field"
//                 value={yearFilter}
//                 onChange={(e) => setYearFilter(e.target.value)}
//               >
//                 <option value="">All Years</option>
//                 {getYearOptions()
//                   .reverse()
//                   .map((year) => (
//                     <option key={year} value={year}>
//                       {year}
//                     </option>
//                   ))}
//               </select>
//             </div>
//             <div className="col-md-3 col-sm-12 mb-3 mx-2">
//               <select
//                 className="form-control movies-field"
//                 value={genreFilter}
//                 onChange={(e) => setGenreFilter(e.target.value)}
//               >
//                 {/* <option value="">All Genres</option>
//                 <option value="Action">Action</option>
//                 <option value="Comedy">Comedy</option>
//                 <option value="Drama">Drama</option> */}
//                 {genres?.map((i,index)=>(
//                   <option value={i?.title} key={index}>{i?.title}</option>
//                 ))}
//               </select>
//             </div>
//             <div className="col-md-1 col-sm-12 mb-3 mx-2 d-flex justify-content-center">
//               <button
//                 className="btn btn-primary search-btn-movies"
//                 onClick={handleSearch}
//                 style={{width:"100%"}}
//               >
//                 <svg
//                   width="15"
//                   height="15"
//                   viewBox="0 0 22 22"
//                   fill="none"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path
//                     d="M15.0291 2.90032C11.4491 -0.429677 5.83861 -0.229726 2.50861 3.35027C-0.821392 6.93027 -0.621197 12.5403 2.9588 15.8703C6.1388 18.8303 10.9191 19.0003 14.2791 16.4903L19.2488 21.1103L21.4393 18.7603L16.4686 14.1403C18.7286 10.6003 18.2091 5.85031 15.0291 2.89031V2.90032ZM13.7586 13.8203C11.3086 16.4503 7.18841 16.6003 4.55841 14.1503C1.92841 11.7003 1.77931 7.58031 4.22931 4.95031C6.67931 2.32031 10.7985 2.17029 13.4285 4.62029C16.0585 7.07029 16.2086 11.1903 13.7586 13.8203Z"
//                     fill="#EDEDED"
//                   />
//                 </svg>
//               </button>
//             </div>
//           </div>
//           {/* Loading indicator */}
//           {loading ? (
//             <Loader
//               type="spinner-default"
//               bgColor={"white"}
//               color={"black"}
//               title={""}
//               size={50}
//             />
//           ) : search ? (
//             // Display search results
//             movies1 &&
//             movies1.map((i, index) => (
//               <div className="col-lg-12 col-md-12 col-sm-12 mt-2" key={i.title}>
//                 <h1
//                   className="header"
//                   style={{ backgroundColor: "#000", color: "white" }}
//                 >
//                   {i?.title}
//                 </h1>
//                 <ScrollContainer className="movies-container">
//                   <MoviePoster
//                     key={i.id}
//                     imageNumber={index + 1}
//                     imageSrc={i.poster_image}
//                     alt={i.slug}
//                     movie={i}
//                   />
//                 </ScrollContainer>
//               </div>
//             ))
//           ) : (
//             // Display regular movies list
//             movies &&
//             movies?.map((i) => (
//               <div className="col-lg-12 col-md-12 col-sm-12 mt-2" key={i.title}>
//                 <h1 className="header" style={{ backgroundColor: "#000" }}>
//                   {i?.title}
//                 </h1>
//                 <ScrollContainer className="movies-container">
//                   {i?.items?.map((poster, index) => (
//                     <MoviePoster
//                       key={poster.id}
//                       imageNumber={index + 1}
//                       imageSrc={poster.poster_image}
//                       alt={poster.slug}
//                       movie={poster}
//                     />
//                   ))}
//                 </ScrollContainer>
//               </div>
//             ))
//           )}

//           {/* Pagination */}
//           {/* <div className="row">
//             <div className="col-md-12 text-center">
//               <nav>
//                 <ul className="pagination">
//                   <li
//                     className={`page-item ${
//                       currentPage === 1 ? "disabled" : ""
//                     }`}
//                   >
//                     <button
//                       className="page-link"
//                       onClick={() => handlePageChange(currentPage - 1)}
//                     >
//                       Prev
//                     </button>
//                   </li>
//                   {Array.from({ length: totalPages }, (_, i) => (
//                     <li
//                       key={i + 1}
//                       className={`page-item ${
//                         currentPage === i + 1 ? "active" : ""
//                       }`}
//                     >
//                       <button
//                         className="page-link"
//                         onClick={() => handlePageChange(i + 1)}
//                       >
//                         {i + 1}
//                       </button>
//                     </li>
//                   ))}
//                   <li
//                     className={`page-item ${
//                       currentPage === totalPages ? "disabled" : ""
//                     }`}
//                   >
//                     <button
//                       className="page-link"
//                       onClick={() => handlePageChange(currentPage + 1)}
//                     >
//                       Next
//                     </button>
//                   </li>
//                 </ul>
//               </nav>
//             </div>
//           </div> */}
//         </div>
//         <footer className="footer">
//         <div
//           className="container"
//           style={{
//             borderTop: "1px solid white",
//             backgroundColor: "white",
//             marginBottom: "60px",
//           }}
//         ></div>

//         <div className="container">
//           <div className="row">
//             <div className="col-lg-6">
//               <div className="footer-logo tex-left">
//                 <img
//                   src="/images/TRUFLIX-logo.svg"
//                   alt="img"
//                   className="logo-img"
//                 />
//                 <p className="text-white mt-2">
//                   Truflix © 2024. All Rights Reserved..
//                 </p>
//               </div>
//             </div>
//             <div className="col-lg-6">
//               <div className="footer-dev">
//                 <nav className="footer-nav footer-link-sec ">
//                   <ul className="footer-links">
//                     <a
//                       href="#"
//                       className="footer-link p-0"
//                       style={{ lineHeight: "5px" }}
//                     >
//                       <li>TERMS AND CONDITIONS</li>
//                     </a>
//                     <a
//                       href="#"
//                       className="footer-link"
//                       style={{ lineHeight: "5px" }}
//                     >
//                       <li>DISCLAIMER</li>
//                     </a>
//                     <a
//                       href="#"
//                       className="footer-link"
//                       style={{ lineHeight: "5px" }}
//                     >
//                       <li>TRUFLIX PRIVATE POLICY</li>
//                     </a>
//                   </ul>
//                 </nav>
//               </div>
//             </div>
//           </div>
//         </div>
//         <style jsx>{`
//           .main-container {
//             width: 100%;
//             background-color: #000;
//             color: #fff;
//             display: flex;
//             flex-direction: column;
//             align-items: center;
//             justify-content: center;
//             padding: 5vw 10vw; /* Using vw units for padding */
//             font-family: Montserrat, sans-serif;
//           }
//           // .site-header {
//           //   width: 100%;
//           //   text-align:center
//           // }
//           // .site-header h1 {
//           //   text-align:center;
//           //   padding-top: 3em !important;

//           // }
//           .guide-description {
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             flex-direction: column;
//           }

//           // @media screen and (max-width: 1440px) {
//           //   .site-header h1 {
//           //     padding-top: 5em !important;
//           //     padding-left: 1rem !important;
//           //   }
//           // }
//           // @media (max-width: 991px) {
//           //   .main-container {
//           //     padding: 5vw; /* Adjust padding for smaller screens */
//           //   }
//           // }
//           .site-title {
//             width: 80%;
//             font-size: 3.5vw; /* Using vw units for font size */
//             font-weight: 700;
//             margin: 0;
//             text-align: center;
//             padding-bottom: 10px;
//             // margin-left: 24px;
//           }
//           @media (max-width: 991px) {
//             .site-title {
//               font-size: 25px; /* Adjust font size for smaller screens */
//             }
//           }
//           .guide-description .description-text {
//             width: 55%;
//             margin-top: 10px;
//             font-size: 1.3vw; /* Using vw units for font size */
//             font-weight: 400;
//             text-align: center;
//             line-height: 25px !important;
//             // margin-bottom: 1vw;
//           }
//           .guide-description .access-btn {
//             background-color: rgba(237, 237, 237, 1);

//             width: 48%;
//             padding-top: 5px;
//             padding-bottom: 5px;
//             font-size: 16px;
//             font-weight: 700;
//             margin-top: 40px;
//             cursor: pointer;
//             border: none;
//           }
//           @media (max-width: 600px) {
//             .accss-btn {
//               padding-top: 15px;
//               padding-bottom: 15px;
//               font-size: 2vw;
//             }
//             .site-title {
//               width: 100%;
//             }
//           }
//           @media (max-width: 991px) {
//             .guide-description .access-btn {
//               font-size: 4vw;
//             }
//             .guide-description .description-text {
//               width: 70%;
//               font-size: 16px;
//               margin-bottom: 12px;
//               line-height: 18px !important;
//             }

//             .footer-nav {
//               margin-top: 20px;
//             }
//             .footer-nav .footer-link {
//               color: #fff;
//               text-decoration: none;
//               margin-right: 1vw;
//               font-size: 2.5vw;
//               margin-top: 100px;
//             }
//           }
//           .footer {
//             margin-top: 6vw; /* Using vw units for margin */
//             font-size: 1vw; /* Using vw units for font size */
//             font-weight: 700;
//             text-align: center;
//           }
//           .footer-nav .footer-link {
//             color: #fff;
//             text-decoration: none;
//             margin-right: 16px;
//             margin-top: 100px;
//           }
//           // @media (max-width: 991px) {
//           //   .footer {
//           //     margin-top: 6.5vw; /* Adjust margin for smaller screens */
//           //   }
//           // }
//         `}</style>
//       </footer>
//       </div>
//       <style>{`
//         /* Your styles here */
//         .header {
//           color: #fff;
//           font-size: 2rem;
//           font-weight: 700;
//           line-height: 59px;
//           text-align: left;
//           padding-left: 9rem;
//           padding-right: 5rem;
//           padding-top: 2em;
//           text-transform: uppercase;
//           font-family: Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
//         }

//         .movies-container {
//           display: flex;
//           gap: 0.9em;
//           padding: 2em;
//           padding-left: 8rem;
//           padding-right: 5rem;
//           background: #000;
//           width: 100%;
//           overflow-x: auto;
//           scrollbar-width: none;
//           -ms-overflow-style: none;
//         }

//         .movies-container::-webkit-scrollbar {
//           display: none;
//         }

//         .movie-poster {
//           flex: 0 0 auto;
//           width: 100%;
//           max-width: 195px;
//           aspect-ratio: 0.68;
//           object-fit: cover;
//           cursor: pointer;
//           margin-right: 1rem;
//         }

//         .poster-title {
//           font-size: 7rem !important;
//           margin-top: -48px;
//           color: #333333;
//           font-weight: 700;
//           font-family: "Montserrat";
//         }
//       `}</style>
//     </>
//   );
// };

// export default ShowList;

import React, { useEffect, useState } from "react";
import Header from "../../components/HeaderPricing/Header";
import ScrollContainer from "react-indiana-drag-scroll";
import { useNavigate } from "react-router-dom";
import { BASEURL } from "../../API/constants";
import Loader from "react-js-loader";
import Footer from "../../components/Footer";

const MoviePoster = ({
  imageSrc,
  alt,
  imageNumber,
  movie,
  showImage,
  showTitle,
  title,
}) => {
  const navigate = useNavigate();
  let data = localStorage.getItem("UserInfo");
  return (
    <>
      {showImage && <p className="poster-title">{imageNumber}</p>}
      <img
        onClick={() => {
          if (data == null) {
            navigate("/get-subscription");
          } else {
            // navigate("/shows-details", {
            //   state: {
            //     movie_id: movie.id,
            //     movie_data: movie,
            //   },
            // });
            navigate(`/shows-details/${movie.id}`)
          }
        }}
        className="movie-poster"
        src={imageSrc}
        alt={alt}
        loading="lazy"
      />
      {showTitle && (
        <p
          style={{
            color: "white",
            fontSize: "16px",
            paddingTop: "10px",
            textAlign: "center",
          }}
        >
          {title}
        </p>
      )}
    </>
  );
};

const ShowList = ({ showHeader }) => {
  const [movies, setMovies] = useState([]);
  const [movies1, setMovies1] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [yearFilter, setYearFilter] = useState("");
  const [genreFilter, setGenreFilter] = useState("");
  const [search, setSearch] = useState(false);
  const [searchPage, setSearchPage] = useState(1);
  const [searchTotalPages, setSearchTotalPages] = useState(1);
  const [genres, setGenres] = useState();
  const [bestOfAll,setBestOfAll] = useState()

  const fetchGenres = async () => {
    try {
      const response = await fetch(
        "https://caferoute66.com/truflix/api/v1/entertainment/master/genres"
      );
      const data = await response.json();
      // console.log("GENRES",data?.data)
      setGenres(data?.data);
    } catch (err) {
      console.log("GENRES ERROR: ", err);
    }
  };
  const fetchBestOfAll = async()=>{
    try {
      setLoading(true)
      const response = await fetch("https://caferoute66.com/truflix/api/v1/entertainment/shows-top-rated")
      let data = await response.json()
      console.log("Best of all time",data)
      setBestOfAll(data)
    } catch (error) {
      setLoading(false)
      console.log("ERROR: ",error)
    }
  }

  useEffect(() => {
    fetchData();
    fetchGenres();
    fetchBestOfAll()
  }, [currentPage]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${BASEURL}/entertainment/get-categorized/shows?page=${currentPage}`
      );
      const data = await response.json();
      setMovies(data.data);
      setTotalPages(data.last_page);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchSearchData = async () => {
    try {
      console.log(
        `${BASEURL}/entertainment/shows?search=${searchTerm}&year=${yearFilter}&genres=${genreFilter}&page=${searchPage}`
      );
      setLoading(true);
      const response1 = await fetch(
        `${BASEURL}/entertainment/shows?search=${searchTerm}&year=${yearFilter}&genres=${genreFilter}&page=${searchPage}`
      );
      const data1 = await response1.json();
      console.log("Search API Response:", data1);
      setMovies1(data1.data);
      setSearchTotalPages(data1.last_page);
      setLoading(false);
      setSearch(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleSearch = () => {
    setSearchPage(1);
    fetchSearchData();
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchPageChange = (pageNumber) => {
    setSearchPage(pageNumber);
    fetchSearchData();
  };

  const getYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = 1900; year <= currentYear; year++) {
      years.push(year);
    }
    return years;
  };
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 1999 },
    (_, index) => 2000 + index
  );

  return (
    <>
      <Header />
      <div className="container-fluid mt-5">
        <div className="row justify-content-center full-w align-items-center">
          <h2 className="text-center text-white">ENDLESS SHOWS</h2>
          <h6 className="text-center text-white mb-2 pb-5">BY TRUFLIX</h6>

          <div data-aos="fade-up" className="container d-flex align-items-center justify-content-center flex-wrap">
            <div className="col-md-4 col-sm-12 mb-3 mx-1">
              <input
                type="text"
                className="form-control movies-field"
                placeholder="Search by Title"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="col-md-2 col-sm-12 mb-3 mx-2">
              <select
                className="form-control movies-field"
                value={yearFilter}
                onChange={(e) => setYearFilter(e.target.value)}
              >
                <option value="">All Years</option>
                {getYearOptions()
                  .reverse()
                  .map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-2 col-sm-12 mb-3 mx-2">
              <select
                className="form-control movies-field"
                value={genreFilter}
                onChange={(e) => setGenreFilter(e.target.value)}
              >
                <option value="Action">Action</option>
                <option value="Comedy">Comedy</option>
                <option value="Drama">Drama</option> 
                <option value="">All Genres</option>
                {genres
  ?.sort((a, b) => a.title.localeCompare(b.title)) // Sort alphabetically by title
  .map((i, index) => (
    <option value={i?.title} key={index}>
      {i?.title}
    </option>
  ))}
              </select>
            </div>
            <div className="col-md-1 col-sm-12 mb-3 mx-2">
              <button
                className="btn btn-primary search-btn-movies"
                onClick={handleSearch}
                style={{ width: "100%" }}
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.0291 2.90032C11.4491 -0.429677 5.83861 -0.229726 2.50861 3.35027C-0.821392 6.93027 -0.621197 12.5403 2.9588 15.8703C6.1388 18.8303 10.9191 19.0003 14.2791 16.4903L19.2488 21.1103L21.4393 18.7603L16.4686 14.1403C18.7286 10.6003 18.2091 5.85031 15.0291 2.89031V2.90032ZM13.7586 13.8203C11.3086 16.4503 7.18841 16.6003 4.55841 14.1503C1.92841 11.7003 1.77931 7.58031 4.22931 4.95031C6.67931 2.32031 10.7985 2.17029 13.4285 4.62029C16.0585 7.07029 16.2086 11.1903 13.7586 13.8203Z"
                    fill="#EDEDED"
                  />
                </svg>
              </button>
            </div>
          </div>

          {loading ? (
            <Loader
              type="spinner-default"
              bgColor={"white"}
              color={"black"}
              title={""}
              size={50}
            />
          ) : search ? (
            <>
              <div data-aos="fade-up" className="search-results-grid">
                {movies1 && movies1.length > 0 ? (
                  movies1.map((i, index) => (
                    <div className="grid-item" key={i.id}>
                      <MoviePoster
                        imageNumber={index + 1}
                        imageSrc={i.poster_image}
                        alt={i.slug}
                        movie={i}
                        showImage={false}
                        showTitle={true}
                        title={i?.title}
                      />
                    </div>
                  ))
                ) : (
                  <div
                    
                    style={{display:"flex", alignItems: "center", justifyContent: "center",width:"100%",height:"50%" }}
                  >
                    <h4 style={{ color: "white" }}>No shows found</h4>
                  </div>
                )}
              </div>
              {/* Pagination for Search Results */}
              <div className="row">
                <div className="col-md-12 text-center">
                  <nav>
                    <ul className="pagination">
                      <li
                        className={`page-item ${
                          searchPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handleSearchPageChange(searchPage - 1)}
                        >
                          Prev
                        </button>
                      </li>
                      {Array.from({ length: searchTotalPages }, (_, i) => (
                        <li
                          key={i + 1}
                          className={`page-item ${
                            searchPage === i + 1 ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handleSearchPageChange(i + 1)}
                          >
                            {i + 1}
                          </button>
                        </li>
                      ))}
                      <li
                        className={`page-item ${
                          searchPage === searchTotalPages ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handleSearchPageChange(searchPage + 1)}
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </>
          ) : (
            movies &&
            movies.map((i) => (
              <div data-aos="fade-up" className="col-lg-12 col-md-12 col-sm-12 mt-2" key={i.title}>
                <h1 className="header" style={{ backgroundColor: "#000" }}>
                  {i?.title}
                </h1>
                <ScrollContainer className="movies-container">
                  {i?.items?.slice(0, 10)?.map((poster, index) => (
                    <MoviePoster
                      key={poster.id}
                      imageNumber={index + 1}
                      imageSrc={poster.poster_image}
                      alt={poster.slug}
                      movie={poster}
                      showImage={true}
                    />
                  ))}
                </ScrollContainer>
              </div>
            ))
          )}
          <div
                data-aos="fade-up"
                className="col-lg-12 col-md-12 col-sm-12 mt-2"
                
              >
                <h1 className="header" style={{ backgroundColor: "#000" }}>
                  BEST OF ALL TIME
                </h1>
                <ScrollContainer className="movies-container">
                  {bestOfAll?.slice(0, 10)?.map((poster, index) => (
                    <MoviePoster
                      key={poster.id}
                      imageNumber={index + 1}
                      imageSrc={poster.poster_image}
                      alt={poster.slug}
                      movie={poster}
                      showImage={true}
                    />
                  ))}
                </ScrollContainer>
              </div>
        </div>
        <footer className="footer">
          <div
            className="container"
            style={{
              borderTop: "0.5px solid gray",
              backgroundColor: "white",
              marginBottom: "60px",
            }}
          ></div>

          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="footer-logo tex-left">
                  <img
                    src="/images/TRUFLIX-logo.svg"
                    alt="img"
                    className="logo-img"
                  />
                  <p className="text-white mt-2">
                    Truflix © 2024. All Rights Reserved..
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footer-dev">
                  <nav className="footer-nav footer-link-sec ">
                  <ul className="footer-links">
                    <a href="/terms-of-use" className="footer-link p-0" style={{lineHeight:"5px"}} >
                      <li>TERMS AND CONDITIONS</li>
                    </a>
                    <a href="/disclaimer" className="footer-link"  style={{lineHeight:"5px"}} >
                      <li>DISCLAIMER</li>
                    </a>
                    <a href="/privacy-policy" className="footer-link"  style={{lineHeight:"5px"}} >
                      <li>TRUFLIX PRIVATE POLICY</li>
                    </a>
                  </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <style jsx>{`
            .main-container {
              width: 100%;
              background-color: #000;
              color: #fff;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              padding: 5vw 10vw; /* Using vw units for padding */
              font-family: Montserrat, sans-serif;
            }
            // .site-header {
            //   width: 100%;
            //   text-align:center
            // }
            // .site-header h1 {
            //   text-align:center;
            //   padding-top: 3em !important;

            // }
            .guide-description {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
            }

            // @media screen and (max-width: 1440px) {
            //   .site-header h1 {
            //     padding-top: 5em !important;
            //     padding-left: 1rem !important;
            //   }
            // }
            // @media (max-width: 991px) {
            //   .main-container {
            //     padding: 5vw; /* Adjust padding for smaller screens */
            //   }
            // }
            .site-title {
              width: 80%;
              font-size: 3.5vw; /* Using vw units for font size */
              font-weight: 700;
              margin: 0;
              text-align: center;
              padding-bottom: 10px;
              // margin-left: 24px;
            }
            @media (max-width: 991px) {
              .site-title {
                font-size: 25px; /* Adjust font size for smaller screens */
              }
            }
            .guide-description .description-text {
              width: 55%;
              margin-top: 10px;
              font-size: 1.3vw; /* Using vw units for font size */
              font-weight: 400;
              text-align: center;
              line-height: 25px !important;
              // margin-bottom: 1vw;
            }
            .guide-description .access-btn {
              background-color: rgba(237, 237, 237, 1);

              width: 48%;
              padding-top: 5px;
              padding-bottom: 5px;
              font-size: 16px;
              font-weight: 700;
              margin-top: 40px;
              cursor: pointer;
              border: none;
            }
            @media (max-width: 600px) {
              .accss-btn {
                padding-top: 15px;
                padding-bottom: 15px;
                font-size: 2vw;
              }
              .site-title {
                width: 100%;
              }
            }
            @media (max-width: 991px) {
              .guide-description .access-btn {
                font-size: 4vw;
              }
              .guide-description .description-text {
                width: 70%;
                font-size: 16px;
                margin-bottom: 12px;
                line-height: 18px !important;
              }

              .footer-nav {
                margin-top: 20px;
              }
              .footer-nav .footer-link {
                color: #fff;
                text-decoration: none;
                margin-right: 1vw;
                font-size: 2.5vw;
                margin-top: 100px;
              }
            }
            .footer {
              margin-top: 6vw; /* Using vw units for margin */
              font-size: 1vw; /* Using vw units for font size */
              font-weight: 700;
              text-align: center;
            }
            .footer-nav .footer-link {
              color: #fff;
              text-decoration: none;
              margin-right: 16px;
              margin-top: 100px;
            }
            // @media (max-width: 991px) {
            //   .footer {
            //     margin-top: 6.5vw; /* Adjust margin for smaller screens */
            //   }
            // }
          `}</style>
        </footer>
      </div>
      <style>{`
        .filter-input-container {
          border-radius: 5px;
        }

        .header {
          color: #fff;
          font-size: 2rem;
          font-weight: 700;
          line-height: 59px;
          text-align: left;
          padding-left: 9rem;
          padding-right: 5rem;
          padding-top: 2em;
          text-transform: uppercase;
          font-family: Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
        }

        .movies-container {
          display: flex;
          gap: 0.9em;
          padding: 2em;
          padding-left: 8rem;
          padding-right: 5rem;
          background: #000;
          width: 100%;
          overflow-x: auto;
          scrollbar-width: none;
          -ms-overflow-style: none;
        }

        .movies-container::-webkit-scrollbar {
          display: none;
        }

        .movie-poster {
          flex: 0 0 auto;
          width: 100%;
          max-width: 195px;
          aspect-ratio: 0.68;
          object-fit: cover;
          cursor: pointer;
          margin-right: 1rem;
        }

        .poster-title {
          font-size: 7rem !important;
          margin-top: -48px;
          color: #333333;
          font-weight: 700;
          font-family: "Montserrat";
        }

        .search-results-grid {
          display: flex;
          flex-wrap: wrap;
          gap: 2.5rem;
          justify-content: flex-start;
          padding: 1em;
        }

        .grid-item {
          flex: 1 0 15%; /* Adjust the percentage to control the number of items per row */
          max-width: 15%;
          box-sizing: border-box;
          margin: 1em;
          text-align: center;
        }
        @media screen and (max-width: 768px) {
          .grid-item {
            flex: 1 0 30%; /* Adjust the percentage for smaller screens */
            max-width: 30%; /* 3 items per row on smaller screens */
          }
        }
      
        @media screen and (max-width: 425px) {
          .grid-item {
            flex: 1 0 46%; /* Adjust the percentage for smaller screens */
            max-width: 46%; /* 2 items per row on smaller screens */
          }
        }
      
        @media screen and (max-width: 400px) {
          .grid-item {
            flex: 1 0 100%; /* Single column on very small screens */
            max-width: 100%;
          }
        }

        @media screen and (max-width: 768px) {
          .movies-container {
            padding: 1em;
          }

          .header {
            font-size: 35px;
            line-height: 37px;
            padding: 60px 10px;
          }

          .movie-poster {
            max-width: 150px;
          }

          .grid-item {
            flex: 1 0 46%; /* Adjust the percentage for smaller screens */
            max-width: 46%;
          }
        }

        @media screen and (max-width: 425px) {
          .header h1 {
            font-size: 16px;
          }

          .grid-item {
            flex: 1 0 100%; /* Single column on very small screens */
            max-width: 100%;
          }
        }

        @media screen and (max-width: 400px) {
          .header h1 {
            font-size: 12px;
          }
        }
      `}</style>
    </>
  );
};

export default ShowList;
