import React from "react";
import Header from "../../components/HeaderPricing/Header";
import { useNavigate } from "react-router-dom";
import CustomPopup from "../../components/CustomPopup/CustomPopup";

const NoSuscriptionscreen = () => {
  const navigate = useNavigate()

  return (
    <div>
      <Header />

      <div className="container-fuild bg-drak">
        <div className="row">
          <div className="col-lg-12 mt-5 h-100">
            <div className="main-price-container">
              <h2 className="text-center text-white mt-5">
                ONLY AVAILABLE<br></br>
                TO TRUFLIX MEMBERS.
              </h2>
              <p className="text-center text-white subs-btn-content">
                This content is only available to Truflix subscribers.
              </p>
              <p className="text-center text-white">
                {" "}
                <button
                  type="button"
                  className="btn btn-light text-center suscribe-btn"
                >
                  {" "}
                  <a className="text-black" onClick={()=>navigate("/get-plan")}>
                    SUBSCRIBE{" "}
                  </a>
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default NoSuscriptionscreen;
