import "./TerritoryConfirmationPage.css";
import Header from "../../components/HeaderPricing/Header";
import CustomHeading from "../../components/CustomHeading/CustomHeading";
import CustomSubheading from "../../components/CustomSubHeading/CustomSubHeading";
import { useLocation, useNavigate } from "react-router-dom";

const ConfirmationSection = ({country,navigate,id,referralCode,countryId}) => {
  let referralObj = {
    countryId:countryId,
    referralId:id,
    referralCode:referralCode,
    locationAvaialble:true,
    referringMembers:true
  }
  return(
    <section className="confirmation-section">
    <CustomHeading width={"60%"} headingText={`DO YOU RESIDE IN ${country}?`} upperCase={"upperCase"} />
    <CustomSubheading
      width={"100%"}
      subheadingText={` This code is only available to people residing in ${country}.`}
    />
    <div className="response-buttons">
      <button  className="no-response" onClick={()=>{
        navigate("/access-code-verification")
      }}>NO, I DO NOT LIVE HERE.</button>
      <button className="yes-response" onClick={()=>{{
        localStorage.setItem("ReferralInfo",JSON.stringify(referralObj))
        navigate("/mandatory",{state:{country:country}})
      }
      }}>YES I LIVE HERE.</button>
    </div>
  </section>
  )
}

export default function TerritoryConfirmationPage() {
  const navigate = useNavigate()
  const location = useLocation()

  let country = location.state.referralData.referral.country
  let referralId = location.state.referralData.id
  let referralCode = location.state.referralData.referral.code
  let country_id = location.state.referralData.referral.country_id

  return (
    <>
      <Header />
      <div className="access-code-1">
        <div className="access-code-2">
          <div className="app-container">
            <ConfirmationSection countryId={country_id} referralCode={referralCode} id={referralId} country={country} navigate={navigate} />
          </div>
        </div>
      </div>
    </>
  );
}
