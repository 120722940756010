// import React from "react";
// import { useNavigate } from "react-router-dom";

// function StreamingGuide() {
//   const navigate = useNavigate();
//   return (
//     <>
//     <div className="container"></div>
//       <main className="main-container">
//         <header className="site-header">
//           <h1 className="site-title">
//             THE GUIDING STAR TO ALL <br /> YOUR STREAMING NEEDS.
//           </h1>
//         </header>
//         <section className="guide-description">
//           <p className="description-text">
//             Truflix is your guide to every movie
//             <br /> and TV show. Find out what to
//             <br /> watch and where.
//           </p>
//           <button className="access-btn" onClick={() => navigate("/pricing")}>
//             ACCESS
//           </button>
//         </section>
//         <footer className="footer">
//           <nav className="footer-nav">
//             <a href="#" className="footer-link">
//               TERMS AND CONDITIONS
//             </a>
//             <a href="#" className="footer-link">
//               TRUFLIX PRIVATE POLICY
//             </a>
//           </nav>
//         </footer>
//       </main>
//       <style jsx>{`
//         .main-container {
//           background-color: #000;
//           color: #fff;
//           display: flex;
//           flex-direction: column;
//           align-items: center;
//           justify-content: center;
//           padding: 5vw 10vw; /* Using vw units for padding */
//           font-family: Montserrat, sans-serif;
//         }
//         .site-header{
//           width:100%;
//         }
//         .site-header h1 {
//           padding-top: 5em !important;
//           padding-left: rem !important;
//         }
//         .guide-description{
//           display: flex;
//           align-items: center;
//           justify-content: center;
//           flex-direction: column;
//         }

//         @media screen and (max-width: 1440px) {
//           .site-header h1 {
//             padding-top: 5em !important;
//             padding-left: 1rem !important;
//           }
//         }
//         @media (max-width: 991px) {
//           .main-container {
//             padding: 5vw; /* Adjust padding for smaller screens */
//           }
//         }
//         .site-header .site-title {
//           font-size: 3.5vw; /* Using vw units for font size */
//           font-weight: 700;
//           margin: 0;
//           text-align: center;
//           padding-bottom: 10px;
//           margin-left: 24px;
//         }
//         @media (max-width: 991px) {
//           .site-header .site-title {
//             font-size: 5vw; /* Adjust font size for smaller screens */
//           }
//         }
//         .guide-description .description-text {
//           margin-top: 10px;
//           font-size: 1.3vw; /* Using vw units for font size */
//           font-weight: 400;
//           text-align: center;
//           // margin-bottom: 1vw;
//         }
//         .guide-description .access-btn {
//           background-color: rgba(237, 237, 237, 1);
//           // color: #000;
//           // padding: 1vw 4vw; /* Using vw units for padding */
//           // font-size: 1.2vw; /* Using vw units for font size */
//           // font-weight: 700;
//           // border: none;
//           // cursor: pointer;
//           // display: block;
//           // margin: 0 auto; /* Center align button */
//           width: 300px;
//           height: 35px;
//           font-size: 16px;
//           font-weight: 700;
//           margin-top: 40px;
//         }
//         @media (max-width: 991px) {
//           .guide-description .access-btn {
//             font-size: 4vw;
//             padding: 4.5vw 15.5vw; /* Adjust button padding for smaller screens */
//           }
//           .guide-description .description-text {
//             font-size: 16px;
//             margin-bottom: 12px;
//           }
//           .footer-nav {
//             margin-top: 10px;
//           }
//           .footer-nav .footer-link {
//             color: #fff;
//             text-decoration: none;
//             margin-right: 1vw;
//             font-size: 2.5vw;
//             margin-top: 10px;
//           }
//         }
//         .footer {
//           margin-top: 5vw; /* Using vw units for margin */
//           font-size: 1vw; /* Using vw units for font size */
//           font-weight: 700;
//           text-align: center;
//         }
//         .footer-nav .footer-link {
//           color: #fff;
//           text-decoration: none;
//           margin-right: 16px;
//         }
//         @media (max-width: 991px) {
//           .footer {
//             margin-top: 6.5vw; /* Adjust margin for smaller screens */
//           }
//         }
//       `}</style>
//     </>
//   );
// }

// export default StreamingGuide;

import React from "react";
import { useNavigate } from "react-router-dom";

function StreamingGuide() {
  const navigate = useNavigate();
  return (
    <>
      <div className="container" style={{ marginTop: "80px" }} data-aos="fade-up" data-aos-duration="4000">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-md-12 col-sm-12 text-center ">
            <main className="main-container">
              {/* <header className="site-header"> */}
              <h1 className="site-title">
                THE GUIDING STAR TO ALL YOUR STREAMING NEEDS.
              </h1>
              {/* </header> */}
              <section className="guide-description">
                <p className="description-text">
                  Truflix is your guide to every movie and TV show. Find out
                  what to watch and where.
                </p>
                <button
                  className="access-btn accss-btn"
                  onClick={() => navigate("/get-subscription")}
                >
                  ACCESS
                </button>
              </section>
            </main>
          </div>
        </div>
      </div>
      
      
    </>
  );
}

export default StreamingGuide;
