import React from 'react'

const CustomPopup = ({closeModal,showModal}) => {
  return (
    <div
            className="hero-modal"
            style={{ display: "none" }}
          >
            <div className="modal-logo-container">
              <h2 className="text-white">TRUFLIX</h2>
              <p style={{ color: "white" }} onClick={() => closeModal()}>
                x
              </p>
            </div>
            <div className="modal-text-container">
              <p>To continue please Login or SignUp to Truflix</p>
            </div>
            <div className="modal-button-container"> 
              <button onClick={()=>navigate("/login")} className="btn btn-light text-center suscribe-btn">
                Continue
              </button> 
            </div>
            {/* <h4>Please create an account to continue</h4> */}
          </div>
  )
}

export default CustomPopup
