import React, { useEffect, useState } from "react";
import Header from "../../components/HeaderPricing/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { BASEURL } from "../../API/constants";
import Loader from "react-js-loader";

const WhatsAppConfirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  console.log("LOCATION", location.state.data);
  let prevData = location?.state?.data;

  //   console.log(price.id)
  // useEffect(() => {
  //     // Function to handle back navigation
  //     const handlePopState = (event) => {
  //       event.preventDefault();
  //       // Navigate to a different screen and pass props/data
  //       navigate('/sign-up-edit', {
  //         replace: true,
  //         state: { // Passing data to the /home route

  //           data: prevData
  //         }
  //       });
  //     };

  //     // Push the current state to prevent going back directly
  //     window.history.pushState(null, null, window.location.href);

  //     // Listen for the popstate event to detect back navigation
  //     window.addEventListener('popstate', handlePopState);

  //     // Cleanup event listener on component unmount
  //     return () => {
  //       window.removeEventListener('popstate', handlePopState);
  //     };
  //   }, [navigate, prevData]);
  // let storeFrmData = localStorage.getItem("formData")
  // let x = JSON.parse(storeFrmData)
  // console.log("formDATA",x)

  const handleSignup = async () => {
    const payload = {
      name: prevData.name,
      email: prevData.email,
      password: prevData.password,
      referral_id: prevData?.referral_id,
      referral_code: prevData?.referral_code,
      country_id: prevData?.country_id,
      location_availability: true,
      referring_members: true,
      whatsapp: prevData.countryCode + prevData.whatsapp,
    };
    // localStorage.setItem("formData",JSON.stringify(payload))
    // navigate("/confirm-whatsapp", {
    //   state: {
    //     data: payload,
    //   },
    // });

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(payload),
    };

    try {
      setLoading(true);
      console.log("PAYLOAD",payload)
      const response = await fetch(`${BASEURL}/users`, options);
      let data = await response.json();
      console.log("sign up data",data)
      if (!data.status) {
        // setSignUpErr(true);
        localStorage.setItem("emailError", true);
        // setError(data.data.email[0]);
        console.log("data?.message",data.data.email[0])
        localStorage.setItem("emailErrMsg", JSON.stringify(data?.message));
        setLoading(false);
        navigate("/sign-up");
      } else {
        // setPasswordErr(false);
        setLoading(false);
        console.log(data)
        
        navigate("/otp-verification", {
          state: { email: data.data.email, data: data.data },
        });
      }
    } catch (error) {
      console.log("Catched Error: ", error);
      setLoading(false);

    }
  };

  return (
    <div>
      <Header />

      <div className="container-fuild bg-drak">
        <div className="row">
          <div className="col-lg-12 mt-5 h-100">
            <div className="main-price-container text-white">
              <h2 className="text-center text-white mt-5 price-heading">
                CONFIRM WHATSAPP
                <br />
                PHONE NUMBER
              </h2>
              <p className="text-center text-white">
                This number will be used for all support.{" "}
              </p>
              <h1
                style={{
                  textAlign: "center",
                  fontWeight: "500",
                  fontSize: "45px",
                  letterSpacing: "3px",
                }}
              >
                {prevData?.countryCode} {prevData?.whatsapp}
              </h1>
              {loading ?
              (
                <Loader
                  type="spinner-default"
                  bgColor={"white"}
                  color={"black"}
                  title={""}
                  size={50}
                />
              )
              :<p className="text-center text-white mt-5">
                {" "}
                <button
                  type="button"
                  className="btn btn-light text-center suscribe-btn"
                   onClick={()=>
                    handleSignup()

                      }
                >
                  CONFIRM
                </button>
              </p>}
              <p
                onClick={() => navigate("/sign-up")}
                style={{ color: "green", cursor: "pointer" }}
                className="text-center"
              >
                CHANGE NUMBER
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsAppConfirmation;
