import React from 'react';

const Header = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-transparent">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <h2 className='text-white log-name'>TRUFLIX</h2>
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav m-auto">
              <li className="nav-item">
                <a className="nav-link" href="#">MOVIES</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">TV</a>
              </li>


              <li className="nav-item">
                <a className="nav-link" href="#">SPORTS</a>
              </li>
            </ul>
            <ul className="navbar-nav ml-2">
              <li className="nav-item">
                <a className="nav-link" href="#">ACCOUNT SIGN OUT</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
