import React, { useState, useRef } from "react";
import "./OtpForm.css"; // Import CSS for styling
import Loader from "react-js-loader";

function OtpForm({buttontext,otplength,onClick,storeOtp,loading}) {
  const [otp, setOtp] = useState(otplength); // Initialize state with 8 empty strings
  const otpInputs = useRef([]);
  const submitButtonRef = useRef();

  const handleChange = (event, index) => {
    const { value } = event.target;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    storeOtp(newOtp);
  
    // Move focus to the next input field if value is entered
    if (value && index < otp.length - 1) {
      otpInputs.current[index + 1].focus();
    }
  };
  

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && !otp[index] && index > 0) {
      const newOtp = [...otp];
      newOtp[index - 1] = "";
      setOtp(newOtp);
      otpInputs.current[index - 1].focus();
    } else if (event.key === "Enter" && index === otp.length - 1) {
      submitButtonRef.current.click(); // Trigger click event on the submit button
    }
  };

  const handleClear = () => {
    setOtp(otplength); // Reset all OTP input fields to empty strings
    otpInputs.current[0].focus(); // Move focus to the first input field after clearing
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // alert("Otp Submitted");
    // console.log("Submitted OTP:", otp.join(""));
    // Add your logic to handle OTP submission
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="otp-container">
        {otp.map((value, index) => (
          <input
            key={index}
            type="text"
            maxLength={1}
            value={value}
            onChange={(event) => handleChange(event, index)} // Pass index to handleChange function
            onKeyDown={(event) => handleKeyDown(event, index)} // Pass index to handleKeyDown function
            className="otp-input"
            ref={(input) => (otpInputs.current[index] = input)} // Set ref for input field
          />
        ))}
      </div>
      <div className="button-container">
        {/* <button type="button" onClick={handleClear}>Clear</button> */}
        {!loading ? <button className="account-submit" type="submit" ref={submitButtonRef} onClick={onClick}>
         {buttontext} 
        </button> :(
          <Loader type="spinner-default" bgColor={"white"} color={"black"} title={""} size={50} />
        )}
      </div>
    </form>
  );
}

export default OtpForm;
