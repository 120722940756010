import React, { useState, useEffect } from "react";
import Header from "../../components/HeaderPricing/Header";
import { BASEURL } from "../../API/constants";
// import 'bootstrap/dist/css/bootstrap.min.css';
import Loader from "react-js-loader";
import Clock from "../../Assets/icons/clock.png";

function EventHighlightPage() {
  const [eventData, setEventData] = useState();
  const [loading, setLoading] = useState(false);

  function formatDate(dateString) {
    // Create a Date object
    let date = new Date(dateString);

    // Define an array of month names
    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Get the month name and day
    let monthName = monthNames[date.getMonth()];
    let day = date.getDate();

    // Return the formatted date
    return `${monthName} ${day}`;
  }

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${BASEURL}/entertainment/events`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        console.log("data", data);
        setEventData(data.data);
        setLoading(false);
        console.log("event data", data.data);
        const eventvar = [];
        data.data.map((item, index) => {
          eventvar.push(index, item.title);
        });
        console.log("Eventvar", eventvar);
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };

    fetchEventData();
  }, []);

  const list = [
    {
      id: 1,
      name: "Fox Sports",
    },
    {
      id: 2,
      name: "Star Network",
    },
    { id: 3, name: "Zee Plus" },
  ];

  return (
    <>
      <Header />
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="text-white ">STUFF TO LOOK FORWARD TO</h1>
            <h6 className="text-white  mt-2">BY TRUFLIX</h6>
          </div>
        </div>
        {loading ? (
          <Loader
            type="spinner-default"
            bgColor={"white"}
            color={"black"}
            title={""}
            size={50}
          />
        ) : (
          eventData &&
          eventData.map((event, index) => (
            <div key={index} className="row mt-5 event-first-sec" data-aos="fade-up">
              <div className="col-lg-5">
                <img
                  // src="images/olympics-2024.png"
                  src={event.poster_image}
                  alt="NBA Finals 2024"
                  className="img-fluid"
                  style={{ float: "right" }}
                />
                {/* <img src={event.poster_image} alt={event.title} className="img-fluid" /> */}
              </div>
              <div className="col-lg-7 text-padding">
                <h3 className="text-white ">{event.title}</h3>
                <h6 className="text-white  mt-2">
                  {event.event_type.title} | {formatDate(event?.date)}
                </h6>
                <p className="text-white mt-3 pb-2 para-width">
                  {event.description}
                </p>
                <div className="container d-flex  align-items-center" style={{
                  marginLeft:"-15px",
                }}>
                  <img src={Clock} style={{ width: "8%", height: "8%" }} />
                  <a
                    href={event?.link}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <h5
                      style={{
                        color: "#5EFF58",
                        marginLeft: "20px",
                        fontSize: "16px",
                        cursor: "pointer",
                        marginTop: "13px",
                      }}
                    >
                      SEE TIMINGS
                    </h5>
                  </a>
                </div>

                <h5
                  className="text-white font-weight-bold mt-4"
                  style={{ marginRight: "10px",marginBottom:"10px",marginTop:"24px !important" }}
                >
                  CHANNELS SHOWING THIS EVENT
                </h5>
                {/* {event.wp_one.map((item,index)=>{
                    <span className="text-white d-block" key={index}>{item}</span>
                })}
                {event.wp_two.map((item,index)=>{
                    <span className="text-white d-block" key={index}>{item}</span>
                })}
                {event.wp_three.map((item,index)=>{
                    <span className="text-white d-block" key={index}>{item}</span>
                })} */}

                {/* <span className="text-white d-block">Fox Sports</span>
                <span className="text-white d-block mt-2">ESPN</span>
                <span className="text-white d-block mt-2">NBA TV</span> */}

                {/* <span style={{color:"white",fontSize:"14px"}} className="d-flex">{list.map((i,index)=>{
                    return i.name + "\t\   |   \t"
                })}</span> */}

                {event &&
                  event?.channels?.map((i, index) => (
                    <div
                      className="container d-flex align-items-start  p-0"
                      key={index}
                    >
                      <div
                        style={{
                          width: "20%",
                          height: "100%",
                          alignItems: "flex-start",
                        }}
                      >
                        <p
                          style={{
                            color: "white",
                            fontSize: "14px",
                            marginRight: "10px",
                          }}
                        >
                          {i?.region}
                        </p>
                      </div>
                      <span style={{ width: "5%" }}></span>
                      <div
                        style={{
                          width: "50%",
                          height: "100%",
                          alignItems: "flex-start",
                        }}
                      >
                        <span
  style={{ color: "white", fontSize: "14px", }}
  className="d-flex"
>
  {i?.channels?.map((channel, index) => {
    return (
      <span key={index} style={{ display: "flex", alignItems: "center" }}>
        {channel}
        {index < i.channels.length - 1 && <span style={{ margin: "0 6px" }}>{"|"}</span>}
      </span>
    );
  })}
</span>
                      </div>
                    </div>
                  ))}
                {/* <div className="container d-flex align-items-start p-0">
                  <div style={{width:"20%",height:"100%",alignItems:"flex-start"}}>
                  <p style={{ color: "white", fontSize: "14px",marginRight:"10px" }}>US & CANADA</p>
                  </div>
                  <span style={{ width: "5%" }}></span>
                  <div style={{width:"50%",height:"100%",alignItems:"flex-start"}}>
                  <span
                    style={{ color: "white", fontSize: "14px" }}
                    className="d-flex"
                  >
                    ESPN {" "} |  Willow TV 
                  </span>
                  </div>
                </div>
                <div className="container d-flex align-items-start p-0">
                  <div style={{width:"20%",height:"100%",alignItems:"flex-start"}}>
                  <p style={{ color: "white", fontSize: "14px",marginRight:"10px" }}>MIDDLE EAST</p>
                  </div>
                  <span style={{ width: "5%" }}></span>
                  <div style={{width:"50%",height:"100%",alignItems:"flex-start"}}>
                  <span
                    style={{ color: "white", fontSize: "14px" }}
                    className="d-flex"
                  >
                    Ten Sports {" "} |  Astro Cricket 
                  </span>
                  </div>
                </div>
                <div className="container d-flex align-items-start p-0">
                  <div style={{width:"20%",height:"100%",alignItems:"flex-start"}}>
                  <p style={{ color: "white", fontSize: "14px",marginRight:"10px" }}>INDIA</p>
                  </div>
                  <span style={{ width: "5%" }}></span>
                  <div style={{width:"50%",height:"100%",alignItems:"flex-start"}}>
                  <span
                    style={{ color: "white", fontSize: "14px" }}
                    className="d-flex"
                  >
                    Fox Sports {" "} |  Star Network {" "} |  Zee Plus
                  </span>
                  </div>
                </div>
                <div className="container d-flex align-items-start p-0">
                  <div style={{width:"20%",height:"100%",alignItems:"flex-start"}}>
                  <p style={{ color: "white", fontSize: "14px",marginRight:"10px" }}>US & CANADA</p>
                  </div>
                  <span style={{ width: "5%" }}></span>
                  <div style={{width:"50%",height:"100%",alignItems:"flex-start"}}>
                  <span
                    style={{ color: "white", fontSize: "14px" }}
                    className="d-flex"
                  >
                    ESPN {" "} |  Willow TV 
                  </span>
                  </div>
                </div>
                <div className="container d-flex align-items-start p-0">
                  <div style={{width:"20%",height:"100%",alignItems:"flex-start"}}>
                  <p style={{ color: "white", fontSize: "14px",marginRight:"10px" }}>MIDDLE EAST</p>
                  </div>
                  <span style={{ width: "5%" }}></span>
                  <div style={{width:"50%",height:"100%",alignItems:"flex-start"}}>
                  <span
                    style={{ color: "white", fontSize: "14px" }}
                    className="d-flex"
                  >
                    Ten Sports {" "} |  Astro Cricket 
                  </span>
                  </div>
                </div> */}
                {/*  <div className="container d-flex align-items-start">
                  <p style={{ color: "white", fontSize: "14px" }}>US & CANADA</p>
                  <span style={{ width: "10%" }}></span>
                  <span
                    style={{ color: "white", fontSize: "14px" }}
                    className="d-flex"
                  >
                    ESPN {" "} |  Willow TV
                  </span>
                </div>
                <div className="container d-flex align-items-start">
                  <p style={{ color: "white", fontSize: "14px" }}>MIDDLE EAST
</p>
                  <span style={{ width: "10%" }}></span>
                  <span
                    style={{ color: "white", fontSize: "14px" }}
                    className="d-flex"
                  >
                    Ten Sports  {" "} |  Astro Cricket 
                  </span>
                </div>
                <div className="container d-flex align-items-start">
                  <p style={{ color: "white", fontSize: "14px" }}>INDIA</p>
                  <span style={{ width: "10%" }}></span>
                  <span
                    style={{ color: "white", fontSize: "14px" }}
                    className="d-flex"
                  >
                    Fox Sports {" "} |  Star Network {" "} |  Zee Plus
                  </span>
                </div> */}
              </div>
            </div>
          ))
        )}
      </div>
      <style jsx>{`
        .para-width {
          width: 85%;
        }
        .text-padding {
          padding-left: 3.2rem;
        }
      `}</style>
    </>
  );
}

export default EventHighlightPage;
