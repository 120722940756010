import React from "react";

const ReferredUsersModal = ({ show, closeModal, referredUsers }) => {
  console.log("referredUsers", referredUsers);
  function formatDateString(dateString) {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    // Capitalize the first letter of the month name
    const capitalizedMonth =
      formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

    return capitalizedMonth;
  }

  return (
    <div
      className="referred__modal__container"
      style={{ display: show ? "block" : "none" }}
    >
      <div className="close__icon__container">
        <p className="modal__title">Referred Users</p>
        <div className="close__icon__box">
          <p onClick={() => closeModal()} style={{cursor:"pointer"}}>X</p>
        </div>
      </div>
      <div className="list__container">
        {/*  */}
        {referredUsers && referredUsers.length > 0 ? (
          referredUsers.map((item, index) => {
            return (
              <div className="referred__card" key={item.id}>
                <div className="name__container">
                  <h2 className="text-white">{item?.name}</h2>
                </div>
                {/* <div className="name__container">
            <h3 className='text-white'>User Name : {item?.username}</h3>
          </div> */}
                <div className="name__container">
                  <h4 className="text-white">Email : {item?.email}</h4>
                </div>
                <div className="name__container code__container">
                  <h4 className="text-white">
                    Referral Code : {item?.access_code}
                  </h4>
                  <h4 className="text-white referral__date">
                    Referral Date : {formatDateString(item?.email_verified_at)}
                  </h4>
                </div>
              </div>
            );
          })
        ) : (
          <div
            className="no-referrals-message"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h2 className="text-white">No referrals found</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReferredUsersModal;
