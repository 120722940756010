// import React from "react";
// import img1 from "../Assets/Images/images/sport-1.png"
// import img2 from "../Assets/Images/images/sport-2.png"

// const ImageColumn = ({ src, alt, title }) => (
//   <div className="image-column">
//     <img loading="lazy" src={src} alt={alt} className="responsive-image" />
//     <div className="event-title">{title}</div>
//   </div>
// );

// function EventHighLights() {
//   const events = [
//     {
//       src: "https://cdn.builder.io/api/v1/image/assets/TEMP/e4990ddbdfdbe86e59d768aa648b0e2a7f9df2c1e2dd383dd051cf8dea2d5eac?apiKey=74b2fddf0d83436fad78ac66773acda0&",
//       alt: "World Cup Highlight",
//       title: "CRICKET WORLD CUP 2023",
//     },
//     {
//       src: "https://cdn.builder.io/api/v1/image/assets/TEMP/1aeea1926dcbd96932a4c3ddf1d331530f648ea71db77700f4bd0d0c74e2fdf9?apiKey=74b2fddf0d83436fad78ac66773acda0&",
//       alt: "Oscars Red Carpet",
//       title: "OSCARS 2024: LIVE FROM THE RED CARPET.",
//     },
//   ];

//   return (
//     <>
//       <header className="header-banner">
//         <h2 style={{ color: "white", marginBottom: "45px" }}>
//           STUFF TO LOOK FORWARD TO
//         </h2>
//         <div className="content-container">

//           <div className="image-column">
//             <img
//               loading="lazy"
//               src={img1}
//               alt="World Cup Highlight"
//               className="responsive-image"
//             />
//             <div className="event-title">CRICKET WORLD CUP 2023</div>
//           </div>
//           <div className="image-column">
//             <img
//               loading="lazy"
//               src={img2}
//               alt="Oscars Red Carpet"
//               className="responsive-image"
//             />
//             <div className="event-title">OSCARS 2024: LIVE FROM THE <br/> RED CARPET.</div>
//           </div>
//         </div>
//       </header>
//       <style jsx>{`
//         header.header-banner {
//           background-color: #000;
//           display: flex;
//           justify-content: space-between;
//           align-items: center;
//           flex-direction: column;
//           // padding: 20px;
//           padding-top: 250px;
//         }
//         .header h1 {
//           color: #fff;
//           font-size: 40.53px;
//           font-weight: 700;
//           line-height: 59px;
//           text-align: left;
//           padding: 2em;
//           padding-top: 4em;
//           white-space: nowrap; /* Prevent line breaks */
//           font-family: Montserrat, -apple-system, Roboto, Helvetica, sans-serif; /* Added font-family */
//         }
//         @media (min-width: 768px) {
//           .header-banner {
//             padding: 150px;
//           }
//         }
//         .content-container {
//           display: flex;
//           flex-direction: column;
//           gap: 20px;
//           width: 110%;
//           max-width: 1502px;
//           margin-top: 20px;
//         }
//         @media (min-width: 768px) {
//           .content-container {
//             flex-direction: row;
//             justify-content: space-between;
//           }
//         }
//         .image-column {
//           display: flex;
//           flex-direction: column;
//           line-height: normal;
//           width: 550px;
//         }
//         .responsive-image {
//           aspect-ratio: 1.54;
//           object-fit: cover;
//           object-position: center;
//           width: 90%;
//           align-self: center;
//         }
//         .event-title {
//           color: #fff;
//           font-size: 18px;
//           font-weight: 700;
//           font-family: "Montserrat", sans-serif;
//           margin-top: 10px;
//           text-align: center;
//         }
//         @media (min-width: 768px) {
//           .event-title {
//             font-size: 22px;
//             margin-top: 30px;
//           }
//         }
//       `}</style>
//     </>
//   );
// }

// export default EventHighLights;

import React, { useEffect, useState } from "react";
import img1 from "../Assets/Images/images/sport-1.png";
import img2 from "../Assets/Images/images/sport-2.png";
import { BASEURL } from "../API/constants";
import Loader from "react-js-loader";
import { useNavigate } from "react-router-dom";
const ImageColumn = ({ src, alt, title }) => (
  <div className="image-column">
    <img loading="lazy" src={src} alt={alt} className="responsive-image" />
    <div className="event-title">{title}</div>
  </div>
);

function EventsHighLights() {
  const navigate = useNavigate()
  const [data,setData] = useState()
  const [loading,setLoading] = useState(false)

  const getData = async ()=>{
    try {
      const response = await fetch(BASEURL+"/entertainment/events?list=homepage")
      const data = await response.json()
      console.log("EVENTS DATA",data)
      setData(data?.data)
    } catch (error) {
      console.log("EVENTS FETCH ERROR: ",error)
    }
  }

  useEffect(()=>{
    getData()
  },[])


  const events = [
    {
      src: "http://cdn.builder.io/api/v1/image/assets/TEMP/e4990ddbdfdbe86e59d768aa648b0e2a7f9df2c1e2dd383dd051cf8dea2d5eac?apiKey=74b2fddf0d83436fad78ac66773acda0&",
      alt: "World Cup Highlight",
      title: "CRICKET WORLD CUP 2023",
    },
    {
      src: "http://cdn.builder.io/api/v1/image/assets/TEMP/1aeea1926dcbd96932a4c3ddf1d331530f648ea71db77700f4bd0d0c74e2fdf9?apiKey=74b2fddf0d83436fad78ac66773acda0&",
      alt: "Oscars Red Carpet",
      title: "OSCARS 2024: LIVE FROM THE RED CARPET.",
    },
  ];

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <h3 className="event-highlight">STUFF TO LOOK FORWARD TO</h3>
          {loading ?<Loader
            type="spinner-default"
            bgColor={"white"}
            color={"black"}
            title={""}
            size={50}
          /> :data?.map((i,index)=>(
            <div className="col-lg-6 col-md-12 col-sm-12  " key={index}>
            <div className="image-column">
              <img
                onClick={()=>navigate("/event-highlights")}
                loading="lazy"
                src={i?.poster_image}
                alt="World Cup Highlight"
                className="responsive-image"
                style={{cursor:"pointer"}}
              />
              <div className="event-title">{i?.title}</div>
            </div>
          </div>
          ))}
          {/* <div className="col-lg-6 col-md-12 col-sm-12  ">
            <div className="image-column">
              <img
                loading="lazy"
                src={img2}
                alt="Oscars Red Carpet"
                className="responsive-image"
              />
              <div className="event-title">
                OSCARS 2024: LIVE FROM THE <br /> RED CARPET.
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <style jsx>{`
        header.header-banner {
          background-color: #000;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
          // padding: 20px;
          padding-top: 250px;
        }
        .header h1 {
          color: #fff;
          font-size: 40.53px;
          font-weight: 700;
          line-height: 59px;
          text-align: left;
          padding: 2em;
          padding-top: 4em;
          white-space: nowrap; /* Prevent line breaks */
          font-family: Montserrat, -apple-system, Roboto, Helvetica, sans-serif; /* Added font-family */
        }

        .event-highlight {
          color: white;
          margin-bottom: 45px;
          margin-top: 250px;
          text-align: center;
          font-size: 1.5rem;
          font-weight: 700;
        }

        @media screen and (max-width: 768px) {
          .event-highlight {
            font-size: 35px;
            line-height: 37px;
          }
        }
        // @media (min-width: 768px) {
        //   .header-banner {
        //     padding: 150px;
        //   }
        // }
        .content-container {
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: 110%;
          max-width: 1502px;
          margin-top: 20px;
        }
        // @media (min-width: 768px) {
        //   .content-container {
        //     flex-direction: row;
        //     justify-content: space-between;
        //   }
        // }
        .image-column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          // width: 550px;
        }
        .responsive-image {
          aspect-ratio: 1.60;
          object-fit: cover;
          object-position: center center;
          width: 90%;
          align-self: center;
        }
        .event-title {
          color: #fff;
          font-size: 18px;
          font-weight: 700;
          font-family: "Montserrat", sans-serif;
          margin-top: 10px;
          text-align: center;
        }
        @media (min-width: 768px) {
          .event-title {
            font-size: 22px;
            margin-top: 30px;
          }
        }
        @media (max-width: 426px) {
          .event-highlight {
            width: 100%;
            text-align: center;
          }
        }
        @media (max-width: 317px) {
          .event-highlight {
            width: 100%;
            text-align: center;
          }
        }
      `}</style>
    </>
  );
}

export default EventsHighLights;
