import * as React from "react";
import OtpForm from "../../components/OtpForm/OtpForm";
import "./AccessCodeEntryPage.css";
// import Header from "../../components/Header/Header";
import Header from '../../components/HeaderPricing/Header';
import CustomHeading from "../../components/CustomHeading/CustomHeading";
import CustomSubheading from "../../components/CustomSubHeading/CustomSubHeading";
import { useLocation, useNavigate } from "react-router-dom";
import { BASEURL } from "../../API/constants";


export default function AccessCodeEntryPage(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const [errorMsg,setErrorMsg] = React.useState("")
  const [error,setError] = React.useState(false)
  const [loading,setLoading] = React.useState(false)
  // console.log("location",location?.state)
  // let userEmail = location?.state?.email
  // let accessToken = location?.state.data.data.token.current_token
  const [referralCode,setReferralCode] = React.useState()
  let referralCodeString = referralCode?.join('')?.toUpperCase()
  let userEmail,accessToken
  const payload = {
    referral_code: referralCodeString,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json"
    },
    body: JSON.stringify(payload),
  };

  const handleReferralCodeSubmission = async () =>{
    try{
      if(referralCodeString == ""){
        setError(true)
        setErrorMsg("Invalid Code")
      }
      console.log("options",options)
      setLoading(true)
    const response  = await fetch(`${BASEURL}/users/verify-access-code`,options)
      let data = await response.json()
      console.log("DAta",data)
      if(!data.status){
        setError(true)
        setErrorMsg("Invalid Code")
        setLoading(false)
      }
      else if(data.status){
        setError(false)
        setLoading(false)
        navigate("/country-verification",{state:{referralData:data.data}})
      }
    }catch(err){
      console.log("Referral code verification ERROR: ",err)
    }
  }





  const headingText = "ONLY AVAILABLE TO A LIMITED CROWD.";
  const subheadingText =
    "Truflix is available by invite only. Enter an access code provided to you in order to subscribe.";

  return (
    <>
      <Header />

      <div className="access-code-1">
        <div className="access-code-2">
          <CustomHeading width={"50%"} headingText={headingText} />
          <CustomSubheading width={"50%"} subheadingText={subheadingText} />

          <div className="otp-form">
            <OtpForm loading={loading} storeOtp={setReferralCode} buttontext={"SUBSCRIBE"} otplength={["", "", "", "", "", ""]} onClick={()=>handleReferralCodeSubmission()}/>
            {error && <p style={{color:"red",paddingTop:"20px"}}>{errorMsg}</p>}
          </div>
        </div>
      </div>
    </>
  );
}
