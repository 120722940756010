import React, { useState } from "react";
// import "../";

const LoginHeader = () => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div>
      <nav
        className={`navbar navbar-expand-lg navbar-black bg-black ${
          collapsed ? "" : "expanded"
        }`}
      >
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <h2 className="text-white log-name">TRUFLIX</h2>
          </a>
          {/* <button
            className={`navbar-toggler ${collapsed ? "" : "collapsed"}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded={!collapsed}
            aria-label="Toggle navigation"
            onClick={toggleNavbar}
          >
            <span className="navbar-toggler-icon top-bar"></span>
          </button> */}
          {/* <div
            className={`collapse navbar-collapse ${collapsed ? "" : "show"}`}
            id="navbarNav"
          > */}
            {/* <ul className="navbar-nav m-auto">
              <li className="nav-item  trueflix-nav">
                <a className="nav-link" href="/movie-list">
                  MOVIES
                </a>
              </li>

              <li className="nav-item  trueflix-nav">
                <a className="nav-link" href="/shows-list">
                  TV
                </a>
              </li>

              <li className="nav-item  trueflix-nav">
                <a className="nav-link" href="/event-highlights">
                  EVENTS
                </a>
              </li>
            </ul> */}
            {/* <ul className="navbar-nav ml-auto d-lg-none">
              {" "}
              {/* Show only on mobile (extra small to large) */}
              {/* <li className="nav-item">
                <a className="nav-link" href="/user-dashboard">
                  ACCOUNT
                </a>
              </li> */}
              {/* <li className="nav-item">
                <a className="nav-link" href="/sign-up">
                  SIGN OUT
                </a>
              </li> */}
            {/* </ul> */} 
            {/* <ul className="navbar-nav ml-2 d-none d-lg-flex">
              {" "}
              {/* Hide on mobile (extra small to large) */}
              {/* <li className="nav-item">
                <a className="nav-link" href="/user-dashboard">
                  ACCOUNT
                </a>
              </li> */}
              {/* <li className="nav-item">
                <a className="nav-link" href="/sign-up">
                  SIGN OUT
                </a>
              </li> */}
            {/* </ul> */} 
          {/* </div> */}
        </div>
      </nav>
    </div>
  );
};

export default LoginHeader;
