import * as React from "react";
import OtpForm from "../../components/OtpForm/OtpForm";
import "./../AccessCodeEntryPage/AccessCodeEntryPage.css";
// import Header from "../../components/Header/Header";
import Header from "../../components/HeaderPricing/Header";
import CustomHeading from "../../components/CustomHeading/CustomHeading";
import CustomSubheading from "../../components/CustomSubHeading/CustomSubHeading";
import "./MembershipConfirmation.css";
import { useNavigate } from "react-router-dom";
export default function MemberShipCancellationPage(props) {
  const headingText = "YOUR SUBSCRIPTION HAS BEEN CANCELLED";
  const subheadingText =
    "This code is only available to people residing in Australia.";
  const navigate = useNavigate();
  return (
    <>
      <Header />

      <div className="access-code-1">
        <div className="access-code-2">
          <CustomHeading width={"50%"} headingText={headingText} />
          {/* <CustomSubheading width={"50%"} subheadingText={subheadingText} /> */}

          <div className="otp-form">
            <button
              className="no-response"
              onClick={() => navigate("/")}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
