
import React from "react";
import './CustomHeading.css';
export default function CustomHeading({ headingText,width,fontSize,lineHeight,upperCase }) {
  return (
    <div className="access-code-heading" style={{width:width}}>
      <h1 className="access-code-heading-text" style={{fontSize:fontSize,lineHeight:lineHeight,textTransform:upperCase}} >{headingText}</h1>
    </div>
  );
}
