import React from "react";
import Header from "../../components/HeaderPricing/Header";


const TermsOfUse = () => {
  return (
    <div>
      <Header />
      <div className="container-fuild">
        <div className="container mt-5 contentContainer">
          <h2 className="text-white">TRUFLIX TERMS OF USE</h2>
          <br />
          <p className="text-white mt-5 pb-5">
            <span style={{ fontWeight: "bold" }}>1. Acceptance of Terms</span>
            <br />
            By accessing or using Truflix.com (“the Website”), you agree to
            these Terms of Use, along with any guidelines, policies, or other
            documents published on the Website. If you do not agree to these
            Terms, please do not use the Website.
            <br />
            <br />
            <span style={{ fontWeight: "bold" }}>
              2. Description of Services
            </span>
            <br />
            Truflix.com is a content discovery platform that helps users locate
            information on where to watch movies, shows, and live events across
            streaming services. Truflix is not a streaming service provider and
            does not host or distribute any multimedia content. Membership
            access costs USD 20 per month, billed upon sign-up and on a
            recurring monthly basis.
            <br />
            <br />
            <span style={{ fontWeight: "bold" }}>
              3. Account Registration and Subscription
            </span>
            <br />
            To access certain features, you must create an account and
            subscribe. By creating an account, you represent that all
            information provided is accurate and agree to keep your details
            updated. Your account is non-transferable, and you are responsible
            for all activity under your account.
            <br />
            <br />
            <span style={{ fontWeight: "bold" }}>
              4. Subscription Fees and Billing
            </span>
            <br />
            The monthly subscription fee of USD 20 is charged upon account
            creation and renews automatically every month unless canceled.
            Charges are nonrefundable, including for any unused portion of the
            subscription period.
            <br />
            <br />
            <span style={{ fontWeight: "bold" }}>
              5. Content and Intellectual Property
            </span>
            <br />
            All content on Truflix.com, including but not limited to text,
            graphics, logos, and software, is owned by or licensed to Truflix
            and is protected by intellectual property laws. You may not
            reproduce, distribute, modify, or create derivative works of any
            content without prior written consent.
            <br />
            <br />
            <span style={{ fontWeight: "bold" }}>6. User Conduct</span>
            <br />
            <br />
            You agree not to:
            <br />
            <br />
            <span style={{ fontWeight: "bold" }}>•</span> Use the Website for
            any illegal purposes;
            <br />
            <span style={{ fontWeight: "bold" }}>•</span> Misrepresent your
            identity or affiliation;
            <br />
            <span style={{ fontWeight: "bold" }}>•</span> Access or attempt to
            access non-public areas of the Website;
            <br />
            <span style={{ fontWeight: "bold" }}>•</span> Interfere with the
            security, performance, or integrity of the Website;
            <br />
            <span style={{ fontWeight: "bold" }}>•</span> Upload or distribute
            malicious software.
            <br />
            <br />
            <span style={{ fontWeight: "bold" }}>
              7. Limitation of Liability{" "}
            </span>
            <br />
            Truflix.com is a content discovery platform, not a content provider.
            We make no guarantees regarding the availability or accuracy of
            content on external streaming sites. We shall not be liable for any
            direct or indirect damages resulting from your use or inability to
            use the Website. 8. Privacy Policy Your use of the Website is
            subject to our Privacy Policy, which explains how we collect, use,
            and share your information. 9. Termination We reserve the right to
            suspend or terminate your account if we suspect any violation of
            these Terms. 10. Changes to Terms Truflix may update these Terms
            periodically. Continued use of the Website after changes indicates
            acceptance of the new Terms.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
