import React from "react";
import Header from "../../components/HeaderPricing/Header";
import Footer from "../../components/Footer";

const PrivacyPolicy = () => {
  return (
    <div>
      <Header />
      <div className="container-fuild">
        <div className="container mt-5 contentContainer">
          <h2 className="text-white">TRUFLIX PRIVACY POLICY</h2>
          <br />
          <p className="text-white mt-5 pb-5">
            Effective Date: [Insert Date] At Truflix, we value your privacy and
            are committed to protecting your personal information. This Privacy
            Policy describes the types of information we collect, how we use it,
            and your rights regarding your data.
            <br />
            <br />
            <span style={{ fontWeight: "bold" }}>
              1. Information We Collect
            </span>{" "}
            We collect personal information to provide and improve our services.
            This includes:
            <br />
            <span style={{ fontWeight: "bold" }}>• Account Information</span>:
            Name, email address, payment information, and other details provided
            during sign-up.
            <br />
            <span style={{ fontWeight: "bold" }}>• Usage Data</span>:
            Information on how you interact with our website, including pages
            visited, time spent, and actions taken.
            <br />
            <span style={{ fontWeight: "bold" }}>• Device Data</span>:
            Information about the device and network used to access our website,
            such as IP address and browser type.
            <br />
            <br />
            <span style={{ fontWeight: "bold" }}>
              2. How We Use Your Information
            </span>
            <br />
            <br />
            We use your information for purposes such as:
            <br />
            <br />
            <span style={{ fontWeight: "bold" }}>• Providing Services</span>: To
            authenticate your account, process payments, and manage your
            subscription.
            <br />
            <span style={{ fontWeight: "bold" }}>• Improving Services</span>: To
            analyze usage patterns and optimize our website’s performance.
            <br />
            <span style={{ fontWeight: "bold" }}>• Communication</span>: To send
            you account-related messages, including service updates, promotional
            offers, and security alerts.
            <br />
            <br />
            <span style={{ fontWeight: "bold" }}>
              3. Sharing Your Information
            </span>
            <br />
            We may share your information with:
            <br />
            <span style={{ fontWeight: "bold" }}>• Service Providers</span>:
            Third-party companies that perform services on our behalf, such as
            payment processing and data analytics.
            <br />
            <span style={{ fontWeight: "bold" }}>• Legal Obligations</span>: In
            response to legal requirements, such as a court order, or to protect
            our rights and the safety of others.
            <br />
            <br />
            <span style={{ fontWeight: "bold" }}>
              4. Cookies and Tracking Technologies
            </span>{" "}
            We use cookies and similar tracking technologies to enhance user
            experience and analyze website performance. You can modify your
            cookie preferences in your browser settings, though this may affect
            certain functionalities.
            <br />
            <br />
            <span style={{ fontWeight: "bold" }}>5. Data Retention</span> We
            retain personal information as long as necessary for the purposes
            outlined in this policy or as required by law.
            <br />
            <br />
            <span style={{ fontWeight: "bold" }}>6. Your Rights</span> You have
            the right to access, update, or delete your personal information. To
            exercise these rights or for any questions about your data, please
            contact us at [contact email].
            <br />
            <br />
            <span style={{ fontWeight: "bold" }}>7. Security Measures</span> We
            employ industry-standard measures to protect your data from
            unauthorized access, alteration, or loss. However, no internet
            transmission is entirely secure, so we cannot guarantee absolute
            security.
            <br />
            <br />
            <span style={{ fontWeight: "bold" }}>
              8. Changes to This Policy{" "}
            </span>
            We may update this Privacy Policy periodically. The latest version
            will always be available on our website, and significant changes
            will be communicated to you.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
