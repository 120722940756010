// import React from 'react';
// import Header from '../../components/HeaderPricing/Header';

// function AcccountcreationSignupPage() {
//   return (
//     <>
//     <Header/>
//     <div className="container mt-5 pb-5">
//       <div className="row">
//         <div className="col-lg-8">
//           <h2 className="mb-5 text-white signup-heading">LET’S GET YOU <br></br>AN ACCOUNT</h2>
//           <div className="form-group justify-content-flex">
//             <div className="col-lg-2 email">
//               <p style={{ marginTop: '30px', paddingLeft: '30px' }} className="email">Email</p>
//             </div>
//             <div className="col-lg-10">
//               <input type="email" className="form-control input-trueflix" id="email" />
//             </div>
//           </div>
//           <div className="form-group justify-content-flex">
//             <div className="col-lg-2 email">
//               <p style={{ marginTop: '20px', paddingLeft: '30px' }} className="email"> CHANGE PASSWORD </p>
//             </div>
//             <div className="col-lg-10">
//               <input type="password" className="form-control input-trueflix" id="email" />
//             </div>
//           </div>
//           <div className="form-group justify-content-flex">
//             <div className="col-lg-2 email">
//               <p style={{ marginTop: '20px', paddingLeft: '30px' }} className="email">CONFIRM PASSWORD</p>
//             </div>
//             <div className="col-lg-10">
//               <input type="password" className="form-control input-trueflix" id="email" />
//             </div>
//           </div>

//           {/* Repeat the above form-group as needed */}
//           <div className="form-group form-check mt-5">
//             <input type="checkbox" className="form-check-input" id="termsCheckbox" />
//             <label className="form-check-label text-white " htmlFor="termsCheckbox">
//               I have read and agree to the <br></br>Terms of accessing and using Truflix.com
//             </label>
//           </div>
//           <button className="account-submit sign-up mt-3">SIGN UP</button>
//         </div>
//         <div className="col-lg-4">

//         </div>
//       </div>
//     </div>
//     </>
//   );
// }

// export default AcccountcreationSignupPage;

import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Loader from "react-js-loader";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Header from "../components/Header";
// import india from "../../Assets"

function RegistrationEdit() {
  const location = useLocation();
  console.log("location edit",location?.state)
  let referralInfo = localStorage.getItem("ReferralInfo");
  let referralData = JSON.parse(referralInfo);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [signupErr, setSignUpErr] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [whatsappNumber, setWhatsAppNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+1");

  const countryCodes = [
    { id: 1, code: "+91", name: "India" },
    { id: 2, code: "+32", name: "Belgium" },
    { id: 3, code: "+1", name: "United States" },
    { id: 4, code: "+44", name: "United Kingdom" },
    { id: 5, code: "+61", name: "Australia" },
    { id: 6, code: "+81", name: "Japan" },
    { id: 7, code: "+49", name: "Germany" },
    { id: 8, code: "+33", name: "France" },
    { id: 9, code: "+39", name: "Italy" },
    { id: 10, code: "+86", name: "China" },
    { id: 11, code: "+34", name: "Spain" },
    { id: 12, code: "+7", name: "Russia" },
    { id: 13, code: "+55", name: "Brazil" },
    { id: 14, code: "+27", name: "South Africa" },
    { id: 15, code: "+46", name: "Sweden" },
    { id: 16, code: "+41", name: "Switzerland" },
    { id: 17, code: "+31", name: "Netherlands" },
    { id: 18, code: "+47", name: "Norway" },
    { id: 19, code: "+82", name: "South Korea" },
    { id: 20, code: "+64", name: "New Zealand" },
    { id: 21, code: "+20", name: "Egypt" },
    { id: 22, code: "+65", name: "Singapore" },
    { id: 23, code: "+66", name: "Thailand" },
    { id: 24, code: "+60", name: "Malaysia" },
    { id: 25, code: "+62", name: "Indonesia" },
    { id: 26, code: "+90", name: "Turkey" },
    { id: 27, code: "+52", name: "Mexico" },
    { id: 28, code: "+98", name: "Iran" },
    { id: 29, code: "+63", name: "Philippines" },
    { id: 30, code: "+48", name: "Poland" },
    { id: 31, code: "+351", name: "Portugal" },
    { id: 32, code: "+45", name: "Denmark" },
    { id: 33, code: "+420", name: "Czech Republic" },
    { id: 34, code: "+30", name: "Greece" },
    { id: 35, code: "+40", name: "Romania" },
    { id: 36, code: "+372", name: "Estonia" },
    { id: 37, code: "+371", name: "Latvia" },
    { id: 38, code: "+370", name: "Lithuania" },
    { id: 39, code: "+375", name: "Belarus" },
    { id: 40, code: "+380", name: "Ukraine" },
    { id: 41, code: "+43", name: "Austria" },
    { id: 42, code: "+386", name: "Slovenia" },
    { id: 43, code: "+421", name: "Slovakia" },
    { id: 44, code: "+385", name: "Croatia" },
    { id: 45, code: "+354", name: "Iceland" },
    { id: 46, code: "+298", name: "Faroe Islands" },
    { id: 47, code: "+358", name: "Finland" },
    { id: 48, code: "+372", name: "Estonia" },
    { id: 49, code: "+254", name: "Kenya" },
    { id: 50, code: "+971", name: "United Arab Emirates" },
  ];

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleSignup = async () => {
    if (!username || !email || !password || !confirmPassword) {
      setSignUpErr(true);
      setError("All fields are required.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setSignUpErr(true);
      setError("Invalid email address.");
      return;
    }

    if (password !== confirmPassword) {
      setSignUpErr(true);
      setError("Passwords do not match.");
      return;
    }

    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,}$/;
    if (!passwordRegex.test(password)) {
      setPasswordErr(true);
      return;
    }

    const payload = {
      name: username,
      email: email,
      password: password,
      referral_id: referralData?.referralId,
      referral_code: referralData?.referralCode,
      country_id: referralData?.countryId,
      location_availability: true,
      referring_members: true,
      whatsapp: countryCode + whatsappNumber,
    };
    navigate("/confirm-whatsapp", {
      state: {
        data: payload,
      },
    });

    // const options = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //   },
    //   body: JSON.stringify(payload),
    // };

    // try {
    //   setLoading(true);
    //   const response = await fetch(`${BASEURL}/users`, options);
    //   let data = await response.json();
    //   if (!data.status) {
    //     setSignUpErr(true);
    //     setError(data.data.email[0]);
    //     setLoading(false);
    //   } else {
    //     setPasswordErr(false);
    //     setLoading(false);
    //     navigate("/otp-verification", {
    //       state: { email: data.data.email, data: data.data },
    //     });
    //   }
    // } catch (error) {
    //   console.log("Catched Error: ", error);
    // }
  };
  const handleChange = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z\s]*$/; // Regular expression to allow only letters and spaces
    if (regex.test(value)) {
      setUsername(value);
    }
  };
  const [selected, setSelected] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const handleSelect = (code) => {
    setCountryCode(code);
    console.log("code box");
    setSelected(code);
    setIsOpen(false); // Close dropdown after selecting
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen); // Toggle dropdown open/close
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <Header />
      <div className="container mt-5 pb-5">
        <div className="row">
          <div className="col-lg-8">
            <h2 className="mb-5 text-white signup-heading">
              LET’S GET YOU <br />
              AN ACCOUNT
            </h2>
            <div className="form-group justify-content-flex">
              <div className="col-lg-2 name">
                <p
                  style={{ paddingLeft: "30px", margin: "0" }}
                  className="name"
                >
                  Name
                </p>
              </div>
              <div className="col-lg-10">
                <TextField
                  type="text"
                  onChange={handleChange}
                  variant="outlined"
                  className="form-control input-trueflix"
                  id="name"
                  value={username}
                  // onChange={(e) => setUsername(e.target.value)}
                  autoComplete="off"
                  fullWidth
                  InputProps={{
                    style: {
                      border: "none",
                      outline: "none",
                      color: "white",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="form-group justify-content-flex">
              <div className="col-lg-2 email">
                <p
                  style={{ paddingLeft: "30px", margin: "0" }}
                  className="email"
                >
                  Email
                </p>
              </div>
              <div className="col-lg-10">
                <TextField
                  type="email"
                  variant="outlined"
                  className="form-control input-trueflix"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="off"
                  fullWidth
                  InputProps={{
                    style: {
                      border: "none",
                      outline: "none",
                      color: "white",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="form-group justify-content-flex">
              <div
                className="col-lg-2 email"
                style={
                  {
                    // backgroundColor:"blue"
                  }
                }
              >
                <div className="col-md-6 col-sm-12 mb-3 mx-2 flex">
                  {/* <select
                    className="form-control mobile-field"
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      color: "white",
                      marginTop: "20px",
                      marginLeft: "14px",
                    }}
                  >
                    {countryCodes.map((i, index) => (
                      <option
                        key={index}
                        style={{ color: "white" }}
                        value={i.code}
                      >
                        <img style={{
                          width:"50px",
                          height:"50px"
                        }} src="https://media.istockphoto.com/id/1471401435/vector/round-icon-of-indian-flag.jpg?s=1024x1024&w=is&k=20&c=wYt8ezq-hWf7P-MSFJ2GJCZ98T-F3n8ao1Y4eN0yATQ=" />
                        {/* <h4>{i.code}</h4> */}
                  {/* </option>
                    ))}
                  </select> */}
                  <div
                    style={{
                      position: "relative",
                      margin: "10px 14px",
                      display: "flex",
                      // backgroundColor: "blue",
                      width: "100px",
                      justifyContent: "center",
                      top: "6px",
                    }}
                  >
                    <div
                      style={{
                        width: "120px",
                        height: "50px",
                        // backgroundColor: "red",
                        // marginTop:"10px"
                        marginLeft: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        cursor: "pointer",
                      }}
                    >
                      <p style={{ marginTop: "16px" }} className="wanumber">
                        Mobile Number
                        {/* {selected ? selected : "Mobile Number"} */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-10"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  
                }}
              >
                <div
                  className="custom-select"
                  ref={dropdownRef}
                  onClick={() => toggleDropdown()}
                  style={{
                    width: "140px",
                    height: "100%",
                    // backgroundColor: "red",
                    zIndex: "2",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "5px",
                    position: "relative",
                    
                  }}
                >
                  <p style={{ margin: "0", cursor: "pointer", color: "white" }}>
                    {countryCode}
                  </p>
                  
                  {isOpen && (
                    <div
                      // className="dropdown-menu"
                      style={{
                        position: "absolute",
                        top: "100%", // Aligns dropdown directly below the red div
                        left: 0,
                        backgroundColor: "#444",
                        border: "1px solid #ccc",
                        zIndex: 1000,
                        width: "160px",
                        overflowY: "scroll",
                        height: "300px",
                        overflowX: "hidden",
                      }}
                    >
                      {countryCodes.map((i, index) => (
                        <div
                          key={index}
                          onClick={() => handleSelect(i.code)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "8px",
                            cursor: "pointer",
                            color: "white",
                            justifyContent: "flex-start",
                            gap: "10px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "13px",
                            }}
                          >
                            {i.code}
                          </span>

                          <span
                            style={{
                              fontSize: "13px",
                            }}
                          >
                            {i.name}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div style={{
                    width:"9px",
                    height:"64px",
                    backgroundColor:"#1a1a1a",
                    // marginLeft:"1px",
                    // marginBottom:"5px"
                  }}></div>
                <TextField
                  type="Mobile Number"
                  variant="outlined"
                  className="form-control input-trueflix whatsapp-input"
                  id="email"
                  value={whatsappNumber}
                  onChange={(e) => setWhatsAppNumber(e.target.value)}
                  autoComplete="off"
                  fullWidth
                  InputProps={{
                    style: {
                      border: "none",
                      outline: "none",
                      color: "white",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <p
              style={{
                color: "white",
                paddingTop: "5px",
                marginBottom: "0px",
                fontSize: "10px",
              }}
            >
              All support is provied on Whatsapp. Please enter the phone number
              for whatsapp
            </p>
            <div
              className="form-group justify-content-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col-lg-2 password">
                <p
                  style={{ paddingLeft: "30px", margin: "0" }}
                  className="password"
                >
                  CHOOSE PASSWORD
                </p>
              </div>
              <div className="col-lg-10">
                <TextField
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  className="form-control input-trueflix"
                  id="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordErr(false);
                  }}
                  autoComplete="off"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          // sx={{ color: "white" }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: {
                      border: "none",
                      outline: "none",
                      color: "white",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="form-group justify-content-flex">
              <div className="col-lg-2 password">
                <p
                  style={{ paddingLeft: "30px", margin: "0" }}
                  className="password"
                >
                  CONFIRM PASSWORD
                </p>
              </div>
              <div className="col-lg-10">
                <TextField
                  type={showConfirmPassword ? "text" : "password"}
                  variant="outlined"
                  className="form-control input-trueflix"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setPasswordErr(false);
                  }}
                  autoComplete="off"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirm password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          // sx={{ color: "white" }}
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: {
                      border: "none",
                      outline: "none",
                      color: "white",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="form-group form-check mt-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="termsCheckbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label
                className="form-check-label text-white"
                htmlFor="termsCheckbox"
              >
                I have read and agree to the <br />
                Terms of accessing and using Truflix.com
              </label>
            </div>
            {signupErr && <p className="text-danger mb-3">{error}</p>}
            {passwordErr && (
              <div style={{ marginTop: "10px", width: "50%" }}>
                <p style={{ color: "white", paddingTop: "10px" }}>
                  Password must have at least
                </p>
                <ul
                  style={{
                    width: "100%",
                    height: "20%",
                    alignItems: "center",
                    marginLeft: "20px",
                  }}
                >
                  <li className="text-danger" style={{ color: "red" }}>
                    1 uppercase letter
                  </li>
                  <li className="text-danger" style={{ color: "red" }}>
                    1 number
                  </li>
                  <li className="text-danger" style={{ color: "red" }}>
                    1 special character
                  </li>
                  <li className="text-danger" style={{ color: "red" }}>
                    8 characters long
                  </li>
                </ul>
              </div>
            )}
            <div className="form-group mt-3">
              {loading ? (
                <Loader
                  type="spinner-default"
                  bgColor={"white"}
                  color={"black"}
                  title={""}
                  size={50}
                />
              ) : (
                isChecked && (
                  <button
                    className="account-submit sign-up mt-3"
                    onClick={handleSignup}
                    style={{ margin: "0" }}
                  >
                    SIGN UP
                  </button>
                )
              )}
              <p
                onClick={() => navigate("/login")}
                style={{
                  color: "white",
                  padding: "15px",
                  paddingLeft: "10px",
                  cursor: "pointer",
                }}
              >
                Already have an account?
              </p>
            </div>
          </div>
          <div className="col-lg-4"></div>
        </div>
      </div>
    </>
  );
}

export default RegistrationEdit;
