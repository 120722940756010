import React, { useEffect, useState } from "react";
import Header from "../../components/HeaderPricing/Header";
import { BASEURL } from "../../API/constants";
import { useNavigate, useNavigation } from "react-router-dom";
import Loader from "react-js-loader";
import ReferredUsersModal from "../../components/ReferredUsersPopup/ReferredUsersModal";

const AccountCreation = () => {
  function getMonthName(monthIndex) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[monthIndex];
  }

  //   const threeMonthsLater = new Date();
  //   threeMonthsLater.setMonth(threeMonthsLater.getMonth() + 3);
  //   const laterMonthName = getMonthName(threeMonthsLater.getMonth());
  //   const laterDate = threeMonthsLater.getDate();
  //   const laterYear = threeMonthsLater.getFullYear()

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const navigate = useNavigate();
  let subid = "sub_1P3CbYGOrqhtJt56fFemh8Pi";
  let x = localStorage.getItem("UserInfo");
  let userToken = localStorage.getItem("UserToken")
  console.log("userToken",userToken)
  let token = localStorage.getItem("UserToken");
  let parsedToken = JSON.parse(token)
  console.log("UserToken",token)
  let userStoredData = JSON.parse(x)
  let accessToken = userStoredData?.data?.user?.token?.current_token;
  let userData = userStoredData?.data?.user;
  
  // console.log("accessToken",userStoredData?.data?.user?.token?.current_token)
  const [userInfo, setUserInfo] = useState();
  const [referredLength, setReferredlength] = useState(0);
  const [loading, setLoading] = useState(false);
  const [referredUsers, setReferredUsers] = useState();
  // console.log("userInfo",userInfo.subscription.ends_at)
  // console.log("userInfo",userInfo.subscription.starts_at)
  // let accessToken = userData?.accessToken
  // console.log("userData",userData)
  //   next 3 months date for referral
  const initialDate = new Date(userInfo?.subscription?.starts_at);
  const threeMonthsLater = new Date(
    initialDate.getFullYear(),
    initialDate.getMonth() + 3,
    initialDate.getDate(),
    initialDate.getHours(),
    initialDate.getMinutes(),
    initialDate.getSeconds()
  );
  const month = threeMonthsLater.getMonth() + 1;
  const date = threeMonthsLater.getDate();
  const year = threeMonthsLater.getFullYear();

  const monthIndex = threeMonthsLater.getMonth();
  const monthName = monthNames[monthIndex];

  // date function
  function formatDate(dateString) {
    // Convert string to Date object
    let dateObj = new Date(dateString);

    // Array of month names
    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Get month, day, and year
    let month = monthNames[dateObj.getMonth()];
    let day = dateObj.getDate();
    let year = dateObj.getFullYear();

    // Format the date
    let formattedDate = month + " " + day + ", " + year;

    return formattedDate;
  }

  const cancelSubscription = async () => {
    try {
      const response = await fetch(`${BASEURL}/stripe/subscription/cancel`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + parsedToken,
        },
        body: JSON.stringify({
          subscription_id: userInfo.subscription.subscription_id,
        }),
      });
      let data = await response.json();
      console.log("cancellaition response", data);
      if (data.status) {
        localStorage.removeItem("UserInfo");
        localStorage.removeItem("UserToken");
        navigate("/subscription-cancelled");
      }
      //   console.log("Subcription cancellation data",data)
    } catch (error) {
      console.log("Subcription cancellation ERROR: ", error);
    }
  };

  const getUserDetails = async () => {
    // console.log("accessToken",accessToken)
    try {
      setLoading(true);
      const response = await fetch(`${BASEURL}/user/details`, {
        method: "GET",
        headers: {
          'Content-Type':'application/json',
          'Accept':'application/json',
          Authorization: "Bearer " + parsedToken,
        },
      });
      let data = await response.json();
      console.log("user Data from api", data.data);
      setReferredlength(data.data?.referred_users?.length);
      if (data.data?.referred_users && data.data?.referred_users) {
        setReferredUsers(data.data?.referred_users);
      }
      setUserInfo(data.data);
      setLoading(false);
    } catch (err) {
      console.log("Error getting user details", err);
      setLoading(false);
    }
  };
  useEffect(() => {
    getUserDetails();
  }, []);
  const [showModal, setShowModal] = useState(false);
  const [referredModal, setReferredModal] = useState(false);
  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const openReferredModal = () => {
    setReferredModal(true);
  };
  const closeReferredModal = () => {
    setReferredModal(false);
  };

  const showPendingReferalls = (arg) => {
    let referrals = "";
    if (arg == 0 || arg == undefined) {
      return "three";
    } else if (arg == 1) {
      return "two";
    } else if (arg == 2) {
      return "one";
    }
  };

  return (
    <div>
      <Header />
      {loading ? (
        <Loader
          type="spinner-default"
          bgColor={"white"}
          color={"black"}
          title={""}
          size={50}
        />
      ) : (
        <>
          <div className="container p-3 pb-5" >
            <div className="d-flex justify-content-center text-center">
              <div className="col-lg-9">
                <h1
                  className="title text-left"
                  style={{ textTransform: "uppercase",paddingTop:"0",marginRight:"20px",marginTop:"60px" }}
                >
                  {userInfo?.name}
                </h1>
              </div>
              <div className="col-lg-3">
                {/* <p className="btn deactivate-p text-white">UPGRADE PACKAGE</p> */}
                <p
                  className="btn deactivate-p text-white"
                  onClick={() => openModal()}
                >
                  DEACTIVATE SUBSCRIPTION
                </p>
               
              </div> 
              
            </div>
            <div className="border-bottom-a"></div>
            <div className="row">
              <div className="col-md-4 mb-4">
                <div className="card accounts-card">
                  <div className="card-body">
                    <h2 className="sectionTitle">DETAILS</h2>
                    <p className="text-white">Membership Start Date</p>
                    <p className="info">
                      {formatDate(userInfo?.subscription?.starts_at)}
                    </p>
                    <p className="text-white">Territory</p>
                    <p className="info">{userInfo?.territory}</p>
                    <p className="text-white">Credentials</p>
                    <p className="info">{userInfo?.email}</p>
                    <div className="card-footer">
                      <button
                        onClick={() => {
                          navigate("/change-password");
                        }}
                        className="btn pass-btn"
                      >
                        CHANGE PASSWORD
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="card accounts-card">
                  <div className="card-body">
                    {/* <h2 className="sectionTitle">PAYMENT</h2>
                <p className="text-white">Current Payment Method</p>
                <p className="info">VISA ENDING 9340</p> */}
                    <p className="text-white">Next Payment</p>
                    <p className="info">
                      {formatDate(userInfo?.subscription?.ends_at)}
                    </p>
                    <br></br>
                    <br></br>
                    <div className="card-footer">
                      <button className="btn pass-btn"
                      //  onClick={()=>openModal()}
                       >
                      CHANGE PAYMENT METHOD
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="card accounts-card">
                  <div className="card-body">
                    <h2 className="sectionTitle">REFERRED USERS</h2>
                    <p className="text-white">Users Referred</p>
                    <div className="pin-box">
                      <p className="user">
                        {referredLength ? referredLength : 0}/3 USERS
                      </p>
                      <p className="user">
                        You still need to refer{" "}
                        {showPendingReferalls(referredLength)} more people to
                        Truflix before {monthName} {date}, {year}, in order to
                        keep your access.
                      </p>
                    </div>
                    <p className="text-white pb-0">Reference Access Code</p>
                    <p className="info">{userInfo?.access_code}</p>
                    <div className="card-footer">
                      <button
                        // onClick={() => openReferredModal()}
                        onClick={()=>navigate("/referred-users",{
                          state:{
                            referredUsers:referredUsers
                          }
                        })}
                        className="btn pass-btn"
                        disabled={referredLength == 0 ? true :false}
                      >
                        SEE REFERRED USERS
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="hero-modal"
            style={{ display: showModal ? "flex" : "none",zIndex:"2" }}
          >
            <div className="modal-logo-container">
              <h2 className="text-white">TRUFLIX</h2>
              <p style={{ color: "white",cursor:"pointer" }} onClick={() => closeModal()}>
                x
              </p>
            </div>
            <div className="modal-text-container">
              <p>Are you sure you want to deactivate your subscription?</p>
            </div>
            <div className="modal-button-container">
              <button
                onClick={() => cancelSubscription()}
                className="btn btn-light text-center suscribe-btn deactivate-sub-btn"
              >
                Continue
              </button>
            </div>
            {/* <h4>Please create an account to continue</h4> */}
          </div>
        </>
      )}
      <ReferredUsersModal
        show={referredModal}
        closeModal={closeReferredModal}
        referredUsers={referredUsers}
      />
    </div>
  );
};

export default AccountCreation;
