import React from "react";
// import Header from "../../components/Header/Header";
import Header from '../../components/HeaderPricing/Header';
import CustomHeading from "../../components/CustomHeading/CustomHeading";
import "./ManadatoryReferencesAcknowledgementPage.css";
import CustomSubheading from "../../components/CustomSubHeading/CustomSubHeading";
import "./../TerritoryConfirmationPage/TerritoryConfirmationPage.css";
import userImage from "./../../Assets/Images/User-1.png";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// Image component for multiple uses
const Image = ({ src, alt }) => (
  <img loading="lazy" src={src} alt={alt} className="image" />
);

// Button component for reuse
const Button = ({ children, onClick,country }) => (
  <button className="button" onClick={onClick}>
    {children}
  </button>
);
const ConfirmationSection = ({navigate, country}) => (
  <section className="confirmation-section">
    <CustomHeading
      width={"90%"}
      fontSize={"37px"}
      lineHeight={"45px"}
      headingText={
        `WITHIN 3 MONTHS, YOU MUST REFER TRUFLIX TO 3 MORE PEOPLE IN ${country} TO STAY A MEMBER.`
      }
      upperCase={"upperCase"}
    />
    <CustomSubheading
      width={"80%"}
      subheadingText={
        "It's not that hard! Refer your favorite streaming guide to three people in your country within three months and continue to enjoy the benefits!"
      }
    />

    <div className="response-buttons">
      <button className="no-response" onClick={()=>navigate("/")}>CANCEL</button>
      <button className="yes-response" onClick={()=>navigate("/sign-up",{state:{country:country}})}>SURE, I UNDERSTAND!</button>
    </div>
  </section>
);
export default function ManadatoryReferencesAcknowledgementPage(props) {
  const navigate = useNavigate()
  const location = useLocation()

  // Array of image data for mapping
  const images = [
    {
      src: userImage,
      alt: "Stream service visual representation",
    },
    {
      src: userImage,
      alt: "Stream service visual representatio",
    },
    {
      src: userImage,
      alt: "Stream service visual representati",
    },
    // Repeated as per the initial structure you had. Consider dynamically filling this in a real app
  ];

  return (
    <>
      <Header />
      <div className="access-code-1">
        <div className="access-code-2">
          <div className="image-gallery">
            {images.map((image, index) => (
              <Image key={index} src={image.src} alt={image.alt} />
            ))}
          </div>

          <div className="app-container">
            <ConfirmationSection country={location.state.country} navigate={navigate}/>
          </div>
        </div>
      </div>

      {/* <div className="subscription-info">
        <CustomHeading headingText={"WITHIN 3 MONTHS, YOU MUST REFER TRUFLIX TO 3 MORE PEOPLE IN AUSTRALIA TO STAY A MEMBER."}/>
        <CustomHeading subheadingText={"It’s not that hard! Refer your favorite streaming guide to three people in your country within three months and continue to enjoy the benefits!"}/>
          
        
      </div>
      <div className="action-buttons">
        <Button onClick={() => console.log("Cancel")}>CANCEL</Button>
        <Button onClick={() => console.log("Sure, I Understand!")}>SURE, I UNDERSTAND!</Button>
      </div> */}
    </>
  );
}
