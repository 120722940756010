import React, { useEffect, useState } from 'react'
import Header from '../../components/HeaderPricing/Header'
import { useNavigate } from 'react-router-dom'


 const Subscriptionscreen = () =>{     
    const navigate = useNavigate()

//   console.log(price.id)

    return (
        <div>
            <Header />

            <div className='container-fuild bg-drak'>
                <div className='row'>
                    <div className='col-lg-12 mt-5 h-100'>
                        <div className='main-price-container text-white'>
                        <h2 className='text-center text-white mt-5 price-heading'>
                        $20 <span className='text-white month-tag'> PER<br/>Month</span><br></br>
                      
                        </h2>
                        <h2 className='text-center fw-700'>  GET UNLIMITED ACCESS TO THE BEST</h2>
                        <h2 className='text-center fw-700'>  STREAMING GUIDE IN THE WORLD.</h2>
                        <p className='text-center text-white'>Truflix is your guide to every movie, TV show and event.  </p>
                        <p className='text-center text-white' style={{marginTop:"-12px"}}>Find out what to watch and where.  </p>
                        <p className='text-center text-white mt-5' > <button type="button" className="btn btn-light text-center suscribe-btn" onClick={()=>
                            navigate("/access-code-verification")
                            // navigate("/sign-up")
                            
                            }>SUBSCRIBE</button></p>

                    </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Subscriptionscreen
