import React from "react";
import Header from "../../components/HeaderPricing/Header";
import Footer from "../../components/Footer";

const Disclaimer = () => {
  return (
    <div>
      <Header />
      <div className="container-fuild">
        <div className="container mt-5 contentContainer">
          <h2 className="text-white">DISCLAIMER</h2>
          <br />
          <p className="text-white mt-5 pb-5">
            The information provided on Truflix.com is for general informational
            purposes only. Truflix endeavors to keep the information up to date
            and correct, but makes no representations or warranties of any kind,
            express or implied, about the completeness, accuracy, reliability,
            suitability, or availability of the content on the website. Any
            reliance you place on such information is strictly at your own risk.<br/><br/>
            Truflix is not responsible for, and expressly disclaims liability
            for, any losses or damages arising out of the use or inability to
            use the information provided, including but not limited to indirect,
            incidental, or consequential damages. Truflix reserves the right to
            modify or remove any content at any time without prior notice.<br/><br/>
            <span style={{fontWeight:"bold"}}>External Links</span>: Truflix.com may contain links to external websites
            that are not operated by us. We have no control over the content and
            practices of these sites and cannot accept responsibility or
            liability for their policies or actions.<br/><br/>
            <span style={{fontWeight:"bold"}}>Entertainment Service
            Disclaimer</span>: Truflix.com provides information about movies, shows,
            and live events but does not host or stream this content directly.
            Access to streaming services is managed by third-party providers,
            and users must comply with the terms set forth by these providers.<br/><br/>
            By using Truflix.com, you agree to this disclaimer and release
            Truflix from all liability arising from the use of information on
            this site.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
