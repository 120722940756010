import React, { useState } from "react";

function PermissionBadge({ text, isSelected, onSelect }) {
  return (
    <label className={`badge ${isSelected ? 'badge-selected' : ''}`}>
      <input
        type="radio"
        name="permission"
        checked={isSelected}
        onChange={onSelect}
        style={{ display: "none" }}
      />
      <div className="badge-icon" />
      <div className="badge-text">{text}</div>
    </label>
  );
}

function PermissionSection({ selectedPermission, onSelectPermission }) {
  return (
    <section>
      <h2>Permission</h2>
      <div className="permissions">
        <PermissionBadge text="Only View" isSelected={selectedPermission === "Only View"} onSelect={() => onSelectPermission("Only View")} />
        <PermissionBadge text="Edit & View" isSelected={selectedPermission === "Edit & View"} onSelect={() => onSelectPermission("Edit & View")} />
        <PermissionBadge text="No Access" isSelected={selectedPermission === "No Access"} onSelect={() => onSelectPermission("No Access")} />
      </div>
    </section>
  );
}

function PermissionTypeSection({ selectedType, onSelectType }) {
  return (
    <section>
      <h2>Permission Type</h2>
      <div className="types">
        <PermissionBadge text="Track List" isSelected={selectedType === "Track List"} onSelect={() => onSelectType("Track List")} />
        <PermissionBadge text="Attendance" isSelected={selectedType === "Attendance"} onSelect={() => onSelectType("Attendance")} />
        <PermissionBadge text="Defaulders" isSelected={selectedType === "Defaulders"} onSelect={() => onSelectType("Defaulders")} />
      </div>
    </section>
  );
}

export default function Test() {
  const [selectedPermission, setSelectedPermission] = useState("");
  const [selectedType, setSelectedType] = useState("");

  return (
    <main className="container">
      <PermissionSection selectedPermission={selectedPermission} onSelectPermission={setSelectedPermission} />
      <PermissionTypeSection selectedType={selectedType} onSelectType={setSelectedType} />
      <style jsx>{`
        .container {
          border-radius: 10px;
          background-color: #f2f6fb;
          max-width: 709px;
          padding: 50px 48px;
          display: flex;
          flex-direction: column;
          align-items: start;
        }
        @media (max-width: 991px) {
          .container { padding: 0 20px; }
        }
        h2 {
          color: #393939;
          font: 900 27px/54% Urbanist, -apple-system, Roboto, Helvetica, sans-serif;
        }
        @media (max-width: 991px) {
          h2 { max-width: 100%; }
        }
        .permissions, .types {
          display: flex;
          gap: 13px;
          padding: 0 2px;
          margin-top: 26px;
          width: 479px;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .permissions, .types { flex-wrap: wrap; }
        }
        .badge {
          border-radius: 3px;
          background-color: #bbd4ff;
          display: flex;
          justify-content: space-between;
          gap: 11px;
          padding: 15px 19px;
          font-size: 16px;
          color: #2e2e2e;
          font-weight: 600;
          letter-spacing: 0.8px;
          cursor: pointer;
        }
        .badge-selected {
          background-color: #004082;
          color: #ffffff;
        }
        .badge-icon {
          width: 20px;
          height: 20px;
          background-color: #004082;
          border-radius: 50%;
        }
        .badge-text {
          flex-grow: 1;
          font-family: Urbanist, sans-serif;
        }
      `}</style>
    </main>
  );
}