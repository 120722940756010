import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "react-js-loader";
import LoginHeader from "../../components/LoginHeader/LoginHeader";
import { BASEURL } from "../../API/constants";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function ChangePasswordPage() {
  const navigate = useNavigate();
  let token = localStorage.getItem("UserToken");
  let accessToken = JSON.parse(token);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(null);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const payload = {
    password: password,
    confirm_password: confirmPassword,
  };

  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
      Accept: "application/json",
    },
    body: JSON.stringify(payload),
  };

  const handleUpdate = async () => {
    if (
      !password ||
      !confirmPassword ||
      password === "" ||
      confirmPassword === ""
    ) {
      setError("All fields are required.");
      return;
    } else if (password !== confirmPassword) {
      setError("Passwords do not match!");
      return;
    }
    try {
      setLoading(true);
      const response = await fetch(`${BASEURL}/user/update-password`, options);
      const data = await response.json();
      if (!data.status) {
        setError(data.message);
        setLoading(false);
      } else {
        setLoading(false);
        setError("");
        // navigate("/");
      }
    } catch (error) {
      console.log("Login Error: ", error);
    }
  };

  return (
    <>
      <LoginHeader />
      <div className="container mt-5 pb-5">
        <div className="row">
          <div className="col-lg-8">
            <h2 className="mb-5 text-white signup-heading">
              CHANGE
              <br />
              PASSWORD
            </h2>
            <div
              className="form-group justify-content-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col-lg-2 email">
                <p
                  style={{ paddingLeft: "30px", margin: "0" }}
                  className="login-password"
                >
                  NEW PASSWORD
                </p>
              </div>
              <div className="col-lg-10">
                <TextField
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  className="form-control login-input-trueflix"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="off"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" style={{marginRight:"10px"}}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          // sx={{ color: "white" }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: {
                      border: "none",
                      outline: "none",
                      color: "white",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div
              className="form-group justify-content-flex"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col-lg-2 email">
                <p
                  style={{ paddingLeft: "30px", margin: "0" }}
                  className="login-password"
                >
                  CONFIRM PASSWORD
                </p>
              </div>
              <div className="col-lg-10">
                <TextField
                  type={showConfirmPassword ? "text" : "password"}
                  variant="outlined"
                  className="form-control login-input-trueflix"
                  id="confirm-password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  autoComplete="off"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" style={{marginRight:"10px"}}>
                        <IconButton
                          aria-label="toggle confirm password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          // sx={{ color: "white" }}
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: {
                      border: "none",
                      outline: "none",
                      color: "white",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </div>
            </div>
            {error && <div className="text-danger mb-3">{error}</div>}
            <div className="form-group mt-3">
              {!loading ? (
                <button
                  className="account-submit sign-up mt-3"
                  onClick={() => handleUpdate()}
                  style={{ margin: "0" }}
                >
                  SUBMIT
                </button>
              ) : (
                <Loader
                  type="spinner-default"
                  bgColor={"white"}
                  color={"black"}
                  title={""}
                  size={50}
                />
              )}
            </div>
          </div>
          <a
            // href="/user-dashboard"
            onClick={()=>navigate("/user-dashboard")}
            className="text-white pt-2 mt-2  link-login"
            style={{ textDecorationLine: "none", paddingTop: "20px" }}
          >
            Cancel{" "}
          </a>
          <div className="col-lg-4"></div>
        </div>
      </div>
      <style>{`
        .filter-input-container {
          border-radius: 5px;
        }

        .header {
          color: #fff;
          font-size: 2rem;
          font-weight: 700;
          line-height: 59px;
          text-align: left;
          padding-left: 9rem;
          padding-right: 5rem;
          padding-top: 2em;
          text-transform: uppercase;
          font-family: Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
        }

        .movies-container {
          display: flex;
          gap: 0.9em;
          padding: 2em;
          padding-left: 8rem;
          padding-right: 5rem;
          background: #000;
          width: 100%;
          overflow-x: auto;
          scrollbar-width: none;
          -ms-overflow-style: none;
        }

        .movies-container::-webkit-scrollbar {
          display: none;
        }

        .movie-poster {
          flex: 0 0 auto;
          width: 100%;
          max-width: 195px;
          aspect-ratio: 0.68;
          object-fit: cover;
          cursor: pointer;
          margin-right: 1rem;
        }

        .poster-title {
          font-size: 7rem !important;
          margin-top: -48px;
          color: #333333;
          font-weight: 700;
          font-family: "Montserrat";
        }

        @media screen and (max-width: 768px) {
          .movies-container {
            padding: 1em;
          }
          .search-btn-movies {
            padding;
          }

          .header {
            font-size: 35px;
            line-height: 37px;
            padding: 60px 10px;
          }
          .movie-poster {
            max-width: 150px;
          }
        }
        @media screen and (max-width: 425px) {
          .header h1 {
            font-size: 16px;
          }
        }
        @media screen and (max-width: 400px) {
          .header h1 {
            font-size: 12px;
          }
        }
      `}</style>
    </>
  );
}

export default ChangePasswordPage;
